<template>
  <Fold title="특약사항">
    <TableView>
      <template v-slot:colgroup>
        <col style="width: 192px" />
        <col />
      </template>
      <template v-slot:body>
        <tr>
          <th>특약사항</th>
          <td class="td_text">
            {{ itemData }}
          </td>
        </tr>
      </template>
    </TableView>
  </Fold>
</template>

<script>
import Fold from "@/components/common/fold/Fold";
import TableView from "@/components/shared/tableView/TableView";

export default {
  name: "SpecialAgreement",
  components: {
    Fold,
    TableView,
  },
  props: {
    itemData: String,
  },
};
</script>
