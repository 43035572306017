<template>
  <Popup popupType="tableType" width="788px">
    <div class="section_comm">
      <div class="head_section">
        <h3 class="tit_section">검수 확인</h3>
        <p v-if="canConfirm" class="desc_tip">
          <span class="ico_purchase ico_exclam" />검수 확인을 위해 의견을 입력헤주시기 바랍니다.
        </p>
        <div v-if="!isInspect && !canConfirm" class="area_right">
          <button type="button" class="btn_tertiary btn_medium" @click="onClickPrintPreview">
            미리보기
          </button>
        </div>
      </div>
      <div class="body_section">
        <div v-if="canConfirm" class="area_view">
          <TableViewWithTitle title="검수 요청 정보">
            <template v-slot:colgroup>
              <col style="width: 192px" />
              <col />
            </template>
            <template v-slot:body>
              <tr v-if="isBeforeInspect">
                <th>납품내역<span class="ico_purchase ico_star">필수항목</span></th>
                <td>
                  <Input :maxLength="20" :value.sync="deliveryHistory" />
                </td>
              </tr>
              <tr v-if="!cancelYn">
                <th>
                  납품완료일<span v-if="isBeforeInspect" class="ico_purchase ico_star"
                    >필수항목</span
                  >
                </th>
                <td>
                  <DatePicker
                    :date.sync="deliveryCompleteDate"
                    @update:date="(date) => onUpdateDeliveryDate(date)"
                  />
                </td>
              </tr>
              <tr v-if="!cancelYn">
                <th>
                  검수완료일<span v-if="isBeforeInspect" class="ico_purchase ico_star"
                    >필수항목</span
                  >
                </th>
                <td>
                  <div class="group_form">
                    <DatePicker
                      :date.sync="inspectCompleteDate"
                      @update:date="(date) => onUpdateInspectDate(date)"
                    />
                    <span v-if="isDateCompareError" class="txt_view_r tc_red">{{
                      compareDateErrorText
                    }}</span>
                  </div>
                </td>
              </tr>
              <tr v-if="!cancelYn">
                <th>
                  검수 증빙 문서<span v-if="isInspect" class="ico_purchase ico_star">필수항목</span>
                </th>
                <td>
                  <FileWrite
                    :dataList="inspectFileList"
                    @fileUploaded="(file) => (inspectFileList = inspectFileList.concat(file))"
                    @fileRemoved="
                      (fileToRemove) =>
                        (inspectFileList = getListAfterRemove(fileToRemove, inspectFileList))
                    "
                  />
                </td>
              </tr>
              <tr>
                <th>의견</th>
                <td>
                  <Input :maxLength="40" :value.sync="etc" />
                </td>
              </tr>
            </template>
          </TableViewWithTitle>
        </div>
        <!-- 2차에 적용예정 -->

        <div v-if="canReject && !isInspect" class="group_btn">
          <button type="button" class="btn_tertiary btn_medium" @click="onClickPrintPreview">
            미리보기
          </button>
        </div>
        <InspectContractInfo v-if="!isInspect" :inspectData="inspectData" />
        <div v-if="!isInitial" class="area_view">
          <TableView>
            <template v-slot:colgroup>
              <col style="width: 192px" />
              <col />
            </template>
            <template v-slot:body>
              <tr>
                <th>납품내역</th>
                <td>{{ inspectData.deliveryHistory }}</td>
              </tr>
            </template>
          </TableView>
        </div>
        <InspectHistory :dataList="histories" :isInspect="isInspect" :cancelYn="cancelYn" />
      </div>
    </div>
    <div class="group_btn">
      <button type="button" class="btn_fourthly btn_medium" @click="onClickClose">닫기</button>
      <button
        v-if="cancelYn"
        type="button"
        class="btn_secondary btn_medium"
        @click="onClickInspectCancel"
      >
        검수 취소
      </button>
      <button
        v-else-if="canReject"
        type="button"
        class="btn_secondary btn_medium"
        @click="onClickReject"
      >
        반려
      </button>
      <button
        v-if="!cancelYn"
        type="button"
        class="btn_primary btn_medium"
        :disabled="isInspectDisabled"
        @click="onClickInspect"
      >
        검수
      </button>
      <button
        v-if="canCompleteInspect && !isInspect"
        type="button"
        class="btn_primary btn_medium"
        @click="onClickCompleteInspect"
      >
        정산 완료
      </button>
    </div>
  </Popup>
</template>

<script>
import Popup from "@/components/layout/popup/Popup";
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import TableView from "@/components/shared/tableView/TableView";
import FileWrite from "@/components/common/file/FileWrite";
import FileView from "@/components/common/file/FileView";
import Input from "@/components/common/input/Input";
import DatePicker from "@/components/common/calendar/DatePicker";

import InspectHistory from "./InspectHistory";
import InspectContractInfo from "./InspectContractInfo";

import { getDateString, dateToString, stringToDate } from "@/utils/dateUtils";
import { addCommaForMoney } from "@/utils/stringUtils";
import { getCountWithCurrency } from "@/utils/spUtils";
import {
  INITIAL,
  REQUESTER_INSPECT,
  REQUESTER_REJECT,
  PURCHASE_INSPECT,
  PURCHASE_CONFIRM,
  INSPECT_REQUEST,
} from "@/constants/inspectStatusList";

import ApiService from "@/services/ApiService";
import LocalStorageManager from "@/LocalStorageManager";

import { ALERT_ACTION } from "@/store/modules/alert/action";

export default {
  name: "InspectConfirmPopup",
  components: {
    Popup,
    TableViewWithTitle,
    TableView,
    FileWrite,
    FileView,
    Input,
    DatePicker,

    InspectHistory,
    InspectContractInfo,
  },
  props: {
    inspectData: Object,
    calculateNum: Number,
    contractId: Number,
    iManager: Array,
  },
  data() {
    return {
      etc: "",
      deliveryHistory: "",
      deliveryCompleteDate: null,
      inspectCompleteDate: null,
      inspectFileList: [],
      isDateCompareError: false,
      compareDateErrorText: "",
      // 2차에 적용에정
      // partnerInspectCompleteDate: null,
    };
  },
  computed: {
    canConfirm() {
      const { stateCode } = this.lastestHistory;

      return (
        stateCode !== "PURCHASE_INSPECT" &&
        stateCode !== "PURCHASE_CONFIRM" &&
        stateCode !== "COMPLETE"
      );
    },
    isInitial() {
      //현업검수일 경우
      const { stateCode } = this.lastestHistory;

      return stateCode == "INITIAL";
    },
    isInspect() {
      return LocalStorageManager.shared.getIsInspect();
    },
    lastestHistory() {
      if (this.histories.length === 0) return null;

      // 맨 뒤가 현재 상태로 변경.
      // return this.histories[ 0 ];
      return this.histories[this.histories.length - 1];
    },
    isInspectDisabled() {
      const { inspectYn } = this.inspectData;

      return inspectYn !== true;
    },

    isBeforeInspect() {
      if (this.isInspect) return false;

      if (!this.lastestHistory) return false;

      const { stateCode } = this.lastestHistory;
      return stateCode === REQUESTER_INSPECT; // 프로세스 순서에서 검수 담당자 검수 완료 상태.
    },
    canReject() {
      if (this.isInspect) return false;

      if (!this.lastestHistory) return false;

      const { stateCode } = this.lastestHistory;
      return stateCode === REQUESTER_INSPECT; // 프로세스 순서에서 검수 담당자 검수 완료 상태.
    },

    histories() {
      const { contractInspectHistoryList } = this.inspectData;

      return contractInspectHistoryList || [];
    },
    cancelYn() {
      const { cancelYn } = this.inspectData;

      return cancelYn || false;
    },
    canCompleteInspect() {
      const { stateCode } = this.lastestHistory;

      return false;
    },
  },
  beforeMount() {
    const { stateCode } = this.lastestHistory;

    if (stateCode === REQUESTER_INSPECT) {
      this.deliveryCompleteDate = stringToDate(this.inspectData.deliveryCompleteDate);
      this.inspectCompleteDate = stringToDate(this.inspectData.inspectCompleteDate);
    }
  },
  methods: {
    getListAfterRemove(fileToRemove, dataList) {
      const ret = dataList.filter((item) => item.attachNum !== fileToRemove.attachNum);

      return ret;
    },
    onUpdateDeliveryDate(date) {
      if (!this.deliveryCompleteDate) {
        this.isDateCompareError = false;
      }
      this.compareDate();
    },
    onUpdateInspectDate(date) {
      if (!this.inspectCompleteDate) {
        this.isDateCompareError = false;
      }
      this.compareDate();
    },

    compareDate() {
      let limitInspectDate = new Date(this.deliveryCompleteDate);
      let deliveryDate = this.deliveryCompleteDate ? new Date(this.deliveryCompleteDate) : null;
      let inspectDate = this.inspectCompleteDate ? new Date(this.inspectCompleteDate) : null;
      limitInspectDate.setDate(deliveryDate.getDate() + 10);

      if (deliveryDate && inspectDate && deliveryDate > inspectDate) {
        this.compareDateErrorText = "검수완료일은 납품완료일보다 선행될 수 없습니다.";
        this.isDateCompareError = true;
        this.inspectCompleteDate = null;
      } else if (limitInspectDate && inspectDate && limitInspectDate < inspectDate) {
        this.compareDateErrorText = "검수완료일은 납품완료일의 10일내로 입력가능합니다. ";
        this.isDateCompareError = true;
        this.inspectCompleteDate = null;
      } else {
        this.isDateCompareError = false;
      }
    },
    onClickClose() {
      this.$emit("onClickClose");
    },
    onClickPrintPreview() {
      this.$emit("onClickPrintPreview");
    },
    onClickInspect() {
      const alertMessage = this.getAlertMessage();

      if (alertMessage) {
        this.$emit("alert", alertMessage);
        return;
      }

      const params = {
        text: "검수 처리 하시겠습니까?",
        onClickY: this.onClickInspectOk,
      };

      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, params);
    },
    onClickInspectOk() {
      this.inspect(this.contractId, this.calculateNum);
    },
    getObjToSend() {
      let obj = {
        etc: this.etc,
      };

      if (this.inspectFileList.length > 0) {
        obj.inspectFile = this.inspectFileList;
      }

      const { deliveryHistory, deliveryCompleteDate, inspectCompleteDate } = this.inspectData;
      // 2차에 적용 예정
      // , partnerInspectCompleteDate

      if (this.deliveryHistory) {
        obj.deliveryHistory = this.deliveryHistory;
      } else if (deliveryHistory) {
        obj.deliveryHistory = deliveryHistory;
      }

      if (this.deliveryCompleteDate) {
        obj.deliveryCompleteDate = dateToString(this.deliveryCompleteDate);
      } else if (deliveryCompleteDate) {
        obj.deliveryCompleteDate = deliveryCompleteDate;
      }

      if (this.inspectCompleteDate) {
        obj.inspectCompleteDate = dateToString(this.inspectCompleteDate);
      } else if (inspectCompleteDate) {
        obj.inspectCompleteDate = inspectCompleteDate;
      }

      return obj;
    },
    getAlertMessage() {
      // 검수담당자는 증빙문서가 필수.
      if (this.isInspect && (!this.inspectFileList || this.inspectFileList.length === 0)) {
        return "검수 증빙 문서를 첨부해주세요.";
      }

      if (this.isBeforeInspect) {
        if (!this.inspectCompleteDate) return "검수완료일을 확인해주세요.";
        if (!this.deliveryCompleteDate) return "납품완료일을 확인해주세요.";
        if (!this.deliveryHistory) return "납품내역을 확인해주세요.";
      } else {
        //다만 구매담당자가 날짜를 변경할 경우 반드시 변경 사유 입력 필수
        const isDeliveryDateChange =
          dateToString(this.deliveryCompleteDate) == this.inspectData.deliveryCompleteDate;
        if (!isDeliveryDateChange && !this.etc) {
          return "날짜를 변경한 경우 의견 항목을 입력해주세요.";
        }

        const isInspectDateChange =
          dateToString(this.inspectCompleteDate) == this.inspectData.inspectCompleteDate;
        if (!isInspectDateChange && !this.etc) {
          return "날짜를 변경한 경우 의견 항목을 입력해주세요.";
        }
      }

      return null;
    },
    async inspect(contractId, calculateNum) {
      // if( this.inspectFileList.length < 1 )  return;

      const path = `${this.$apiPath.INSPECT}/${this.contractId}/inspect/${this.calculateNum}`;

      const obj = this.getObjToSend();

      const result = await ApiService.shared.putData(path, obj);

      if (result.code !== "200") {
        this.$emit("alert", result.text);
        return;
      }

      this.$emit("onInspectSuccess", this.contractId);
    },
    onClickInspectCancel() {
      const params = {
        text: "검수를 취소 하시겠습니까?",
        onClickY: this.onClickCancelOk,
      };

      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, params);
    },
    onClickCancelOk() {
      this.inspectCancel(this.contractId, this.calculateNum);
    },
    async inspectCancel(contractId, calculateNum) {
      const path = `${this.$apiPath.INSPECT}/${this.contractId}/cancel/${this.calculateNum}`;

      const obj = this.getObjToSend();

      const result = await ApiService.shared.putData(path, obj);

      if (result.code !== "200") {
        this.$emit("alert", result.text);
        return;
      }

      // cancel success 지만 일단 넘어가자.
      this.$emit("onInspectSuccess", this.contractId);
    },
    onClickReject() {
      let payload = {};

      const { deliveryHistory, deliveryCompleteDate, inspectCompleteDate } = this.inspectData;

      if (this.deliveryHistory) {
        payload.deliveryHistory = this.deliveryHistory;
      } else if (deliveryHistory) {
        payload.deliveryHistory = deliveryHistory;
      }

      if (this.inspectCompleteDate) {
        payload.inspectCompleteDate = dateToString(this.inspectCompleteDate);
      } else if (inspectCompleteDate) {
        payload.inspectCompleteDate = inspectCompleteDate;
      }

      if (this.deliveryCompleteDate) {
        payload.deliveryCompleteDate = dateToString(this.deliveryCompleteDate);
      } else if (deliveryCompleteDate) {
        payload.deliveryCompleteDate = deliveryCompleteDate;
      }

      this.$emit("onClickReject", payload);
    },
    onClickCompleteInspect() {
      this.completeInspect();
    },
    async completeInspect() {
      const path = `${this.$apiPath.INSPECT}/${this.contractId}/complete/${this.calculateNum}`;

      const obj = this.getObjToSend();

      const result = await ApiService.shared.putData(path, obj);

      if (result.code !== "200") {
        this.$emit("alert", result.text);
        return;
      }

      this.$emit("onInspectSuccess", this.contractId);
    },
  },
};
</script>
