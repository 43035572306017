<template>
  <Fold title="정산 정보">
    <Board :disableHover="true" :dataList="dataList" :showPagination="false">
      <template v-slot:colgroup>
        <col style="width: 56px" />
        <!-- NO. -->
        <col style="width: 547px" />
        <!-- 품명 -->
        <col style="width: 637px" />
        <!-- 단가 -->
      </template>
      <template v-slot:tr>
        <th>No.</th>
        <th>품목</th>
        <th>단가</th>
      </template>
      <template v-slot:rows>
        <template v-for="(item, index) in dataList">
          <tr :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ item.item || "" }}</td>
            <td>{{ getUnitPrice(item) }}</td>
          </tr>
        </template>
      </template>
      <template v-slot:emptyList>
        <tr>
          <td colspan="3" class="td_empty">정산 정보가 없습니다.</td>
        </tr>
      </template>
    </Board>
  </Fold>
</template>

<script>
import Fold from "@/components/common/fold/Fold";
import Board from "@/components/common/board/Board";

import { getMoneyToNumber, addCommaForMoney } from "@/utils/stringUtils";

export default {
  name: "SettleUnitCost",
  components: {
    Fold,
    Board,
  },
  props: {
    itemData: Object,
  },
  computed: {
    dataList() {
      const { contractItemList } = this.itemData;

      return contractItemList || [];
    },
  },
  methods: {
    getUnitPrice(item) {
      const { unitPrice } = item;
      if (!unitPrice) return "";

      return addCommaForMoney(unitPrice);
    },
  },
};
</script>
