<template>
  <div class="area_view">
    <template v-if="orderInspectedGoodsList.length > 0">
      <TableViewWithTitle :title="`기검수품목`">
        <template v-slot:colgroup>
          <!-- No -->
          <col style="width: 40px" />
          <!-- 품명 -->
          <col />
          <!-- 품목코드 -->
          <col style="width: 110px" />
          <!-- 자산코드 -->
          <col style="width: 110px" />
          <!-- 단위 -->
          <col style="width: 80px" />
          <!-- 수량 -->
          <col style="width: 80px" />
          <!-- 예산금액 -->
          <col style="width: 120px" />
          <!-- 소비자가 -->
          <col style="width: 120px" />
          <!-- 계약금액 -->
          <col style="width: 120px" />
          <!-- 할인율(%) -->
          <col style="width: 80px" />
          <!-- 부가세 -->
          <col style="width: 120px" />
          <!-- 절감액 -->
          <col style="width: 120px" />
          <!-- 절감율(%) -->
          <col style="width: 80px" />
          <!-- 품목상세 -->
          <col style="width: 120px" />
        </template>
        <template v-slot:header>
          <th scope="col">No.</th>
          <th scope="col">품명</th>
          <th scope="col">품목코드</th>
          <th scope="col">자산코드</th>
          <th scope="col">단위</th>
          <th scope="col">수량</th>
          <th scope="col">예산금액</th>
          <th scope="col">소비자가</th>
          <th scope="col">계약금액</th>
          <th scope="col">할인율(%)</th>
          <th scope="col">부가세</th>
          <th scope="col">절감액</th>
          <th scope="col">절감율(%)</th>
          <th scope="col">품목상세</th>
        </template>
        <template v-slot:body>
          <SupplyInfoLine
            v-for="(item, index) in orderInspectedGoodsList"
            :key="index"
            :item="item"
            :index="index"
          />
        </template>
      </TableViewWithTitle>
    </template>
    <TableViewWithTitle
      :title="`${itemData.assetTypeName ? itemData.assetTypeName : ''} 품목 상세`"
    >
      <template v-slot:colgroup>
        <!-- No -->
        <col style="width: 40px" />
        <!-- 품명 -->
        <col />
        <!-- 품목코드 -->
        <col style="width: 110px" />
        <!-- 자산코드 -->
        <col style="width: 110px" />
        <!-- 단위 -->
        <col style="width: 80px" />
        <!-- 수량 -->
        <col style="width: 80px" />
        <!-- 예산금액 -->
        <col style="width: 120px" />
        <!-- 소비자가 -->
        <col style="width: 120px" />
        <!-- 계약금액 -->
        <col style="width: 120px" />
        <!-- 할인율(%) -->
        <col style="width: 80px" />
        <!-- 부가세 -->
        <col style="width: 120px" />
        <!-- 절감액 -->
        <col style="width: 120px" />
        <!-- 절감율(%) -->
        <col style="width: 80px" />
        <!-- 품목상세 -->
        <col style="width: 120px" />
      </template>
      <template v-slot:header>
        <th scope="col">No.</th>
        <th scope="col">품명</th>
        <th scope="col">품목코드</th>
        <th scope="col">자산코드</th>
        <th scope="col">단위</th>
        <th scope="col">수량</th>
        <th scope="col">예산금액</th>
        <th scope="col">소비자가</th>
        <th scope="col">계약금액</th>
        <th scope="col">할인율(%)</th>
        <th scope="col">부가세</th>
        <th scope="col">절감액</th>
        <th scope="col">절감율(%)</th>
        <th scope="col">품목상세</th>
      </template>
      <template v-slot:body>
        <SupplyInfoLine
          v-for="(item, index) in orderGoodsList"
          :key="index"
          :item="item"
          :index="index"
        />
      </template>
    </TableViewWithTitle>
    <TableView>
      <template v-slot:colgroup>
        <col style="width: 192px" />
        <col style="width: 428px" />
        <col style="width: 620px" />
      </template>
      <template v-slot:body>
        <tr>
          <th>제안단계 견적가</th>
          <td>{{ orderGoodsAddInfo.estimatePrice | currency }}</td>
          <td rowspan="2" class="list_total">
            <dl class="list_total">
              <template v-if="Number(totalInspectAmount) > 0">
                <dt>기 검수금액</dt>
                <dd>
                  {{ totalInspectAmount | currency }}
                </dd>
              </template>
              <dt>총 절감금액</dt>
              <dd>
                {{ orderGoodsAddInfo.totalSavingAmount | currency }}<br />
                <span class="txt_util">부가세(VAT) 별도</span>
              </dd>
              <template v-if="Number(totalInspectAmount) > 0">
                <dt>총 계약금액</dt>
                <dd>
                  {{
                    (Number(orderGoodsAddInfo.totalContractAmount) + Number(totalInspectAmount))
                      | currency
                  }}<span class="txt_util">({{ orderGoodsAddInfo.totalVat | currency }})</span
                  ><br />
                  <span class="txt_util">부가세(VAT) 별도</span>
                </dd>
              </template>
              <template v-else>
                <dt>총 계약금액</dt>
                <dd>
                  {{ orderGoodsAddInfo.totalContractAmount | currency
                  }}<span class="txt_util">({{ orderGoodsAddInfo.totalVat | currency }})</span
                  ><br />
                  <span class="txt_util">부가세(VAT) 별도</span>
                </dd>
              </template>
            </dl>
          </td>
        </tr>
        <tr>
          <th>제안단계 견적서</th>
          <td>
            <FileView :dataList="attachFile.estimateFile || []" />
          </td>
        </tr>
      </template>
    </TableView>
  </div>
</template>

<script>
import TableHead from "@/components/shared/TableHead";

import TableView from "@/components/shared/tableView/TableView";
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import FileView from "@/components/common/file/FileView";

import SupplyInfoLine from "@/components/admin/contract/view/SupplyInfoLine.vue";

export default {
  name: "SupplyInfo",
  components: {
    TableHead,

    TableViewWithTitle,
    TableView,
    FileView,
    SupplyInfoLine,
  },
  props: {
    itemData: Object,
    orderGoodsList: Array,
    orderInspectedGoodsList: { type: Array, default: [] },
    totalInspectAmount: String | Number,
    orderGoodsAddInfo: Object,
    attachFile: Object,
  },
  computed: {},
};
</script>
