<template>
  <TableViewWithTitle title="추가 정보">
    <template v-slot:body>
      <tr>
        <th>설치(수행) 장소</th>
        <td>
          {{ itemData.orderGoodsAddInfo.delivery }}
        </td>
        <th>공급 목적물</th>
        <td>
          {{ itemData.orderGoodsAddInfo.why }}
        </td>
      </tr>
      <tr>
        <th>계약서 첨부파일</th>
        <td>
          <FileView :dataList="attachFile.contractFile || []" />
        </td>
        <th>발주서 첨부파일</th>
        <td>
          <FileView :dataList="attachFile.purchaseOrderFile || []" />
        </td>
      </tr>
      <tr v-if="itemData.offlineYn">
        <th>OffLine 계약</th>
        <td colspan="3">OffLine 계약</td>
      </tr>
      <tr>
        <th>특기사항</th>
        <td colspan="3" v-html="computedSpecialAgreement"></td>
      </tr>
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import FileView from "@/components/common/file/FileView";

import { escapeRegExp } from "@/utils/spUtils";
export default {
  name: "AddInfo",
  components: {
    TableViewWithTitle,
    FileView,
  },
  props: {
    itemData: Object,
    attachFile: Object,
  },
  computed: {
    computedSpecialAgreement() {
      return escapeRegExp(this.itemData.orderGoodsAddInfo.specialAgreement);
    },
  },
};
</script>
