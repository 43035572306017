<template>
  <Popup popupType="tableType" width="980px">
    <div class="section_comm">
      <div class="head_section">
        <h3 class="tit_section group_form">
          업무수행도 평가<span class="bar_view" />
          <div class="txt_view">{{ isInspect ? "검수" : "구매" }}담당자</div>
        </h3>
      </div>
      <div class="body_section">
        <TableViewWithTitle title="거래정보">
          <template v-slot:colgroup>
            <col style="width: 192px" />
            <col style="width: 267px" />
            <col style="width: 192px" />
            <col style="width: 267px" />
          </template>
          <template v-slot:body>
            <tr>
              <th>계약명</th>
              <td class="td_ellip">
                <span class="w210 txt_ellip">{{ rowData.description || "" }}</span>
              </td>
              <th>업체명</th>
              <td>{{ rowData.companyName || "" }}</td>
            </tr>
            <tr>
              <th>검수담당자</th>
              <td>{{ rowData.inspectManager || "" }}</td>
              <th>구매담당자</th>
              <td>{{ rowData.purchaseManager || "" }}</td>
            </tr>
          </template>
        </TableViewWithTitle>
        <TableViewWithTitle title="평가항목">
          <template v-slot:colgroup>
            <col style="width: 192px" />
            <col style="width: 724px" />
          </template>
          <template v-slot:body>
            <tr v-if="!isInspect">
              <th>가격 적정성</th>
              <td>
                <p class="desc_view">구매 가격의 적정성을 평가해주세요.</p>
                <Radio
                  :name="performanceEvaluate06.name"
                  :dataList="performanceEvaluate06.dataList"
                  :selectedId.sync="performanceEvaluate06.selectedId"
                />
              </td>
            </tr>
            <tr v-if="isInspect">
              <th>업무 난이도</th>
              <td>
                <p class="desc_view">파트너사에게 요청한 업무의 난이도를 평가해주세요.</p>
                <Radio
                  :name="performanceEvaluate01.name"
                  :dataList="performanceEvaluate01.dataList"
                  :selectedId.sync="performanceEvaluate01.selectedId"
                />
              </td>
            </tr>
            <tr v-if="isInspect">
              <th>
                요구품질 충족도
                <div class="tooltip_guide">
                  <span class="ico_purchase ico_tooptip">설명보기</span>
                  <div class="box_tooltip">
                    <span class="ico_purchase ico_arr_tooptip" />
                    <strong class="tit_tooltip">* 요구품질 충족도 평가 항목 안내</strong>
                    <dl class="list_tooltip">
                      <dt>매우 만족</dt>
                      <dd>요구한 수준보다 훨씬 뛰어난 품질로 납품함</dd>
                      <dt>만족</dt>
                      <dd>요구한 수준을 충족함</dd>
                      <dt>불만족</dt>
                      <dd>업무 수행에 지장이 있는 정도는 아니나 요구한 수준에 미달</dd>
                      <dt>매우 불만족</dt>
                      <dd>업무 수행에 지장이 있을 정도로 요구 수준에 미치지 못해 재요청함</dd>
                    </dl>
                  </div>
                </div>
              </th>
              <td>
                <p class="desc_view">납품된 물품 또는 서비스가 요구한 품질은 충족했나요?</p>
                <Radio
                  :name="performanceEvaluate02.name"
                  :dataList="performanceEvaluate02.dataList"
                  :selectedId.sync="performanceEvaluate02.selectedId"
                />
              </td>
            </tr>
            <tr v-if="isInspect">
              <th>
                납기일정 준수 여부
                <div class="tooltip_guide">
                  <span class="ico_purchase ico_tooptip">설명보기</span>
                  <div class="box_tooltip">
                    <span class="ico_purchase ico_arr_tooptip" />
                    <strong class="tit_tooltip">* 납기일정 준수 여부 평가 항목 안내</strong>
                    <dl class="list_tooltip">
                      <dt>매우 만족</dt>
                      <dd>납기 일정을 맞출 수 없는 상황임에도 파트너사의 역량으로 납기를 맞춤</dd>
                      <dt>만족</dt>
                      <dd>약속한 납기를 문제없이 맞춤</dd>
                      <dt>불만족</dt>
                      <dd>특정 이유로 인해 납기 일정을 맞추지 못함</dd>
                      <dt>매우 불만족</dt>
                      <dd>납기 일정을 맞출 수 없는 아무런 이유가 없음에도 납기가 늦어진 경우</dd>
                    </dl>
                  </div>
                </div>
              </th>
              <td>
                <p class="desc_view">약속한 납기 일정을 준수했나요?</p>
                <Radio
                  :name="performanceEvaluate03.name"
                  :dataList="performanceEvaluate03.dataList"
                  :selectedId.sync="performanceEvaluate03.selectedId"
                />
              </td>
            </tr>
            <tr>
              <th>
                업무 협조도
                <div class="tooltip_guide">
                  <span class="ico_purchase ico_tooptip">설명보기</span>
                  <div class="box_tooltip">
                    <span class="ico_purchase ico_arr_tooptip" />
                    <strong class="tit_tooltip">* 업무 협조도 평가 항목 안내</strong>
                    <dl class="list_tooltip">
                      <dt>매우 만족</dt>
                      <dd>요청사항에 대한 피드백이 매우 빠르고 커뮤니케이션이 매우 매끄러움</dd>
                      <dt>만족</dt>
                      <dd>요청사항에 대한 피드백이 원할하고 커뮤니케이션에 어려움이 없었음</dd>
                      <dt>불만족</dt>
                      <dd>업무에 지장이 있을 수준은 아니나 커뮤니케이션에 자잘한 어려움을 겪음</dd>
                      <dt>매우 불만족</dt>
                      <dd>업무에 큰 지장이 있을 정도로 커뮤니케이션이 원할하지 못함</dd>
                    </dl>
                  </div>
                </div>
              </th>
              <td>
                <p class="desc_view">업무 수행 시 파트너사와의 커뮤니케이션이 원할했나요?</p>
                <Radio
                  :name="performanceEvaluate04.name"
                  :dataList="performanceEvaluate04.dataList"
                  :selectedId.sync="performanceEvaluate04.selectedId"
                />
              </td>
            </tr>
            <tr>
              <th>
                재거래 의향
                <div class="tooltip_guide">
                  <span class="ico_purchase ico_tooptip">설명보기</span>
                  <div class="box_tooltip">
                    <span class="ico_purchase ico_arr_tooptip" />
                    <strong class="tit_tooltip">* 재거래 의향 평가 항목 안내</strong>
                    <dl class="list_tooltip">
                      <dt>매우 만족</dt>
                      <dd>다음 업무 시에도 반드시 이 파트너사와 함께 일하고 싶음</dd>
                      <dt>만족</dt>
                      <dd>별다른 문제가 생기지 않는 한 이 파트너사와 거래를 계속할 의향이 있음</dd>
                      <dt>불만족</dt>
                      <dd>더 나은 파트너사가 있다면 변경을 고려하고 싶음</dd>
                      <dt>매우 불만족</dt>
                      <dd>다음 업무 시에는 절대로 이 파트너사와 함께 일하고 싶지 않음</dd>
                    </dl>
                  </div>
                </div>
              </th>
              <td>
                <p class="desc_view">다시 일하고 싶은 파트너 인가요?</p>
                <Radio
                  :name="performanceEvaluate05.name"
                  :dataList="performanceEvaluate05.dataList"
                  :selectedId.sync="performanceEvaluate05.selectedId"
                />
              </td>
            </tr>
          </template>
        </TableViewWithTitle>
        <TableViewWithTitle title="종합의견">
          <template v-slot:colgroup>
            <col style="width: 192px" />
            <col style="width: 724px" />
          </template>
          <template v-slot:body>
            <tr>
              <th>강점</th>
              <td>
                <div class="group_form">
                  <p class="desc_view">파트너사의 가장 큰 강점(장점)은 무엇인가요?</p>
                  <p class="desc_tip">* 최소 1개, 최대 3개의 키워드를 선택해주세요.</p>
                </div>
                <ul class="list_keyword">
                  <li v-for="(item, index) in keywordDataList" :key="index">
                    <!--<button :class="['btn_keyword', isSelectedItem('s',item)]" :disabled="isSelectedItem('s',item) ==='disabled'" @click="onClickKeyWord('s',item)">{{ item.name }}</button>-->
                    <button
                      :class="[
                        'btn_keyword',
                        item.code === 'NO' ? getButtonNoClassName('s') : isSelectedItem('s', item),
                      ]"
                      :disabled="
                        item.code === 'NO' ? false : isSelectedItem('s', item) === 'disabled'
                      "
                      @click="item.code === 'NO' ? onClickNo('s', item) : onClickKeyWord('s', item)"
                    >
                      {{ item.name }}
                    </button>
                  </li>
                </ul>
                <div class="group_form">
                  <button
                    :class="['btn_keyword', isSelectedItem('s', keywordAddItem)]"
                    :disabled="isSelectedItem('s', keywordAddItem) === 'disabled'"
                    @click="onClickKeyWord('s', keywordAddItem)"
                  >
                    키워드 추가
                  </button>
                  <Input
                    class="w560"
                    :isDisabled="!isSelectedItem('s', keywordAddItem)"
                    :value.sync="strengthKeywordValue"
                    placeholder="키워드 추가를 선택하여 입력해주세요"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>약점</th>
              <td>
                <div class="group_form">
                  <p class="desc_view">파트너사의 가장 큰 약점(단점)은 무엇인가요?</p>
                  <p class="desc_tip">* 최소 1개, 최대 3개의 키워드를 선택해주세요.</p>
                </div>
                <ul class="list_keyword">
                  <li v-for="(item, index) in keywordDataList" :key="index">
                    <!--<button :class="['btn_keyword', isSelectedItem('w',item)]" :disabled="isSelectedItem('w',item) ==='disabled'" @click="onClickKeyWord('w',item)">{{ item.name }}</button>-->
                    <button
                      :class="[
                        'btn_keyword',
                        item.code === 'NO' ? getButtonNoClassName('w') : isSelectedItem('w', item),
                      ]"
                      :disabled="
                        item.code === 'NO' ? false : isSelectedItem('w', item) === 'disabled'
                      "
                      @click="item.code === 'NO' ? onClickNo('w', item) : onClickKeyWord('w', item)"
                    >
                      {{ item.name }}
                    </button>
                  </li>
                </ul>
                <div class="group_form">
                  <button
                    :class="['btn_keyword', isSelectedItem('w', keywordAddItem)]"
                    :disabled="isSelectedItem('w', keywordAddItem) === 'disabled'"
                    @click="onClickKeyWord('w', keywordAddItem)"
                  >
                    키워드 추가
                  </button>
                  <Input
                    class="w560"
                    :isDisabled="!isSelectedItem('w', keywordAddItem)"
                    :value.sync="weaknessKeywordValue"
                    placeholder="키워드 추가를 선택하여 입력해주세요"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>의견</th>
              <td :class="['h96', { td_text: isEvaluated }]">
                {{ isEvaluated && etc ? etc : "" }}
                <Textarea
                  v-if="!isEvaluated"
                  :value.sync="etc"
                  placeholder="위 항목 외에 의견이 있다면 자유롭게 남겨주세요"
                />
              </td>
            </tr>
          </template>
        </TableViewWithTitle>
      </div>
    </div>
    <div class="group_btn">
      <button type="button" class="btn_fourthly btn_medium" @click="onClickClose">취소</button>
      <button v-if="isEvaluated" type="button" class="btn_primary btn_medium" @click="onClickAgain">
        재평가진행
      </button>
      <button v-else class="btn_primary btn_medium" @click="onClickSubmitCheck">평가완료</button>
    </div>
  </Popup>
</template>

<script>
import Popup from "@/components/layout/popup/Popup";
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import Radio from "@/components/common/radio/Radio";
import Input from "@/components/common/input/Input";
import Textarea from "@/components/common/textarea/Textarea";
import TableHead from "@/components/shared/TableHead";

import ApiService from "@/services/ApiService";
import { addCommaForMoney, getMoneyToNumber } from "@/utils/stringUtils";

import { getIdWithName } from "@/utils/spUtils";
import { ALERT_ACTION } from "@/store/modules/alert/action";

export default {
  name: "PerformanceEvaluatePopup",
  components: {
    Popup,
    TableViewWithTitle,
    Radio,
    Input,
    Textarea,
    TableHead,
  },
  props: {
    // reviewOpinion: String,
    isEvaluated: Boolean,
    isInspect: Boolean,
    itemId: String,
    rowData: Object,
  },
  data() {
    return {
      performanceEvaluate01: {
        name: "performanceEvaluate01",
        dataList: [
          { id: 2, text: "매우 까다로움" },
          { id: 0, text: "일반적인 수준" },
          { id: -1, text: "매우 단순" },
        ],
        selectedId: "",
      },
      performanceEvaluate02: {
        name: "performanceEvaluate02",
        dataList: [
          { id: 9, text: "매우 만족" },
          { id: 6, text: "만족" },
          { id: 3, text: "불만족" },
          { id: 0, text: "매우 불만족" },
        ],
        selectedId: "",
      },
      performanceEvaluate03: {
        name: "performanceEvaluate03",
        dataList: [
          { id: 9, text: "매우 만족" },
          { id: 6, text: "만족" },
          { id: 3, text: "불만족" },
          { id: 0, text: "매우 불만족" },
        ],
        selectedId: "",
      },
      performanceEvaluate04: {
        name: "performanceEvaluate04",
        dataList: [
          { id: 9, text: "매우 만족" },
          { id: 6, text: "만족" },
          { id: 3, text: "불만족" },
          { id: 0, text: "매우 불만족" },
        ],
        selectedId: "",
      },
      performanceEvaluate05: {
        name: "performanceEvaluate05",
        dataList: [
          { id: 9, text: "매우 만족" },
          { id: 6, text: "만족" },
          { id: 3, text: "불만족" },
          { id: 0, text: "매우 불만족" },
        ],
        selectedId: "",
      },
      performanceEvaluate06: {
        name: "performanceEvaluate06",
        dataList: [
          { id: 7, text: "적당함" },
          { id: 4, text: "아쉬움" },
        ],
        selectedId: "",
      },
      keywordDataList: [],
      strengthList: [],
      weaknessList: [],
      strengthKeywordValue: "",
      weaknessKeywordValue: "",
      keywordAddItem: { code: "KW", name: "키워드추가", desc: "키워드추가" },
      etc: "",
      //contractInfo: {companyName:'',description:'',inspectManager:''},
      contractInfo: {},

      isSelectedStrengthNo: false,
      isSelectedWeeknessNo: false,
    };
  },
  beforeMount() {
    if (this.isEvaluated) {
      this.radioDataDisabled(this.performanceEvaluate01);
      this.radioDataDisabled(this.performanceEvaluate02);
      this.radioDataDisabled(this.performanceEvaluate03);
      this.radioDataDisabled(this.performanceEvaluate04);
      this.radioDataDisabled(this.performanceEvaluate05);
      this.radioDataDisabled(this.performanceEvaluate06);
    }
    //this.getData();

    let setKeywordDataList = this.rowData.code;

    this.keywordDataList = setKeywordDataList.filter((item) => {
      if (item.name != "키워드추가") {
        return item;
      }
    });
  },
  methods: {
    onClickNo(strGroupType, item) {
      const isStrength = strGroupType === "s";

      if (isStrength) {
        this.isSelectedStrengthNo = !this.isSelectedStrengthNo;

        this.strengthList = [];
        if (this.isSelectedStrengthNo) {
          this.strengthList.push(item);
        }
      } else {
        this.isSelectedWeeknessNo = !this.isSelectedWeeknessNo;

        this.weaknessList = [];
        if (this.isSelectedWeeknessNo) {
          this.weaknessList.push(item);
        }
      }
    },
    getButtonNoClassName(strGroupType) {
      const isStrength = strGroupType === "s";

      if (isStrength) {
        return this.isSelectedStrengthNo ? "on" : "";
      } else {
        return this.isSelectedWeeknessNo ? "on" : "";
      }
    },
    // async getData() {
    //   const status       = this.isInspect ? 'I' : 'P';
    //   const path = `${ this.$apiPath.EVALUATE_CODE}/${ this.itemId }/${ status }`

    //   const result = await ApiService.shared.getData( path );

    //   // text alter창으로 띄우기
    //   if( result.code != "200" )  {
    //     this.alert(result.text);
    //     this.onClickCloseOk();
    //     return;
    //   }

    //   if( !result.data )  return;

    //   let obj = {};
    //   obj.companyName = result.data.companyName;
    //   obj.description = result.data.description;
    //   obj.inspectManager = result.data.inspectManager;

    //   this.contractInfo = obj;

    //   let setKeywordDataList = result.data.code;

    //   this.keywordDataList = setKeywordDataList.filter(item => {
    //     if(item.name != '키워드추가'){
    //       return item;
    //     }
    //   });

    // },
    radioDataDisabled(target) {
      target.dataList.filter((item) => {
        if (item.id != target.selectedId) {
          item.isDisabled = true;
        } else if (item.score) {
          this.selectedScore = this.selectedScore + item.score;
        }
      });
    },
    onClickKeyWord(type, item) {
      if (this.isSelectedItem(type, item) == "on") {
        if (type === "s") {
          this.strengthList = this.strengthList.filter((targetItem) => targetItem !== item);
        } else {
          this.weaknessList = this.weaknessList.filter((targetItem) => targetItem !== item);
        }
      } else {
        if (type === "s") {
          if (this.strengthList.length == 3) {
            alert("최대 3개의 키워드만 선택이 가능합니다");
            return;
          }

          const findNo = this.strengthList.find((item) => {
            return item.code == "NO";
          });

          const noIdx = this.strengthList.indexOf(findNo);
          if (noIdx > -1) this.strengthList.splice(noIdx, 1);

          this.strengthList.push(item);

          this.isSelectedStrengthNo = false;
        } else {
          if (this.weaknessList.length == 3) {
            alert("최대 3개의 키워드만 선택이 가능합니다");
            return;
          }

          const findNo = this.weaknessList.find((item) => {
            return item.code == "NO";
          });

          const noIdx = this.weaknessList.indexOf(findNo);
          if (noIdx > -1) this.weaknessList.splice(noIdx, 1);

          this.weaknessList.push(item);

          this.isSelectedWeeknessNo = false;
        }
      }
    },
    isSelectedItem(type, item) {
      const strengthSeletedItem = this.strengthList.filter((strengthItem) => strengthItem == item);
      const weaknessSeletedItem = this.weaknessList.filter((weaknessItem) => weaknessItem == item);
      if (item === this.keywordAddItem) {
        if (type == "s") {
          return strengthSeletedItem && strengthSeletedItem[0] === item ? "on" : "";
        } else {
          return weaknessSeletedItem && weaknessSeletedItem[0] === item ? "on" : "";
        }
      }
      if (strengthSeletedItem && strengthSeletedItem[0] === item) {
        return type == "s" ? "on" : "disabled";
      } else if (weaknessSeletedItem && weaknessSeletedItem[0] === item) {
        return type == "s" ? "disabled" : "on";
      }
      return;
    },
    onClickClose() {
      const params = {
        text: "수행도평가를 취소하시겠습니까?<br> 취소 할 경우 입력된 내용은 모두 삭제됩니다. ",
        onClickY: this.onClickCloseOk,
      };

      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, params);

      //this.$emit( 'onClickClose' );
    },
    onClickCloseOk() {
      this.$emit("onClickClose");
    },
    onClickSubmitCheck(e) {
      let obj = this.getDataForSave();

      const alertMessage = this.getAlertMessage(obj);
      if (alertMessage) {
        alert(alertMessage);
        return;
      }

      const params = {
        text: "입력하신 내용으로 평가를 완료하시겠습니까?",
        onClickY: () => this.onClickSubmitAction(obj),
      };

      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, params);
    },
    onClickSubmitAction(obj) {
      this.$emit("onClickSubmitAction", obj);
    },
    getDataForSave() {
      const obj = {};

      if (!this.isInspect) {
        obj.priceScore = this.performanceEvaluate06.selectedId; // 가격 적정성
      } else {
        obj.qualityScore = this.performanceEvaluate02.selectedId; // 요구 품질 적정성
        obj.scheduleScore = this.performanceEvaluate03.selectedId; // 일정 점수
        obj.taskScore = this.performanceEvaluate01.selectedId; // 업무 난이도
      }

      obj.cooperateScore = this.performanceEvaluate04.selectedId; // 업무 협조도
      obj.tradingScore = this.performanceEvaluate05.selectedId; // 거래 점수  - 현업,구매 나눠서 tradingScoreI, P
      obj.status = this.isInspect ? "I" : "P"; // 상태
      obj.note = this.etc; // 비고
      obj.strengthList = [];
      obj.weaknessList = [];

      this.strengthList.forEach((item) => {
        let strengthObj = {};
        strengthObj.evaluateCode = item.code;
        strengthObj.evaluateName = item.name;

        if (item.code == "KW") {
          strengthObj.keyword = this.strengthKeywordValue;
        }

        obj.strengthList.push(strengthObj);
      });

      this.weaknessList.forEach((item) => {
        let weaknessObj = {};
        weaknessObj.evaluateCode = item.code;
        weaknessObj.evaluateName = item.name;

        if (item.code == "KW") {
          weaknessObj.keyword = this.weaknessKeywordValue;
        }

        obj.weaknessList.push(weaknessObj);
      });

      return obj;
    },
    getAlertMessage(obj) {
      if (obj.status == "I") {
        if (obj.taskScore === "") return "업무 난이도의 답변을 선택해주세요.";
        if (obj.qualityScore === "") return "요구품질 충족도의 답변을 선택해주세요.";
        if (obj.scheduleScore === "") return "납기일정 준수 여부의 답변을 선택해주세요.";
      } else {
        if (obj.priceScore === "") return "가격 적정성의 답변을 선택해주세요.";
      }

      if (obj.cooperateScore === "") return "업무 협조도의 답변을 선택해주세요.";
      if (obj.tradingScore === "") return "재거래 의향의 답변을 선택해주세요.";

      if (obj.strengthList.length === 0) return "종합의견의 강점을 선택해주세요";
      if (obj.weaknessList.length === 0) return "종합의견의 약점을 선택해주세요";

      return null;
    },
    onChange(to, from) {},
  },
};
</script>
