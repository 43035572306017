<template>
  <div class="area_view">
    <Board :disableHover="true" :dataList="[inspectData]" :showPagination="false">
      <template v-slot:colgroup>
        <!-- <col style="width: 180px;" />
        <col style="width: 144px;" />
        <col style="width: 184px;" />
        <col style="width: 220px;" /> -->
        <col style="width: 168px" />
        <col style="width: 136px" />
        <col style="width: 118px" />
        <col style="width: 118px" />
        <col style="width: 86px" />
        <col style="width: 98px" />
      </template>
      <template v-slot:tr>
        <th>계약 기간</th>
        <th>파트너사 명</th>
        <th>최종계약금액</th>
        <th>금번검수금액</th>
        <th>납품완료일</th>
        <th>검수완료일</th>
      </template>
      <template v-slot:rows>
        <tr>
          <td>{{ dateRange }}</td>
          <td>{{ companyName }}</td>
          <td class="td_break">{{ finalPrice }} {{ inspectData.currencyCode }}</td>
          <td class="td_break">{{ inspectPrice }} {{ inspectData.currencyCode }}</td>
          <td>{{ deliveryCompleteDate }}</td>
          <td>{{ inspectCompleteDate }}</td>
        </tr>
      </template>
      <!-- <template v-slot:emptyList>
        <tr>
          <td colspan="4" class="td_empty">검색 결과가 없습니다</td>
        </tr>
      </template>-->
    </Board>
  </div>
</template>

<script>
import Board from "@/components/common/board/Board";

import InspectContractInfoMixin from "./InspectContractInfoMixin";
import { getDateString } from "@/utils/dateUtils";

export default {
  name: "InspectContractInfo",
  components: {
    Board,
  },
  mixins: [InspectContractInfoMixin],
  computed: {
    deliveryCompleteDate() {
      return getDateString(this.inspectData.deliveryCompleteDate) || "-";
    },
    inspectCompleteDate() {
      return getDateString(this.inspectData.inspectCompleteDate) || "-";
    },
  },
};
</script>
