<template>
  <Popup popupType="printType">
    <div class="section_comm">
      <div class="body_section">
        <div class="box_print">
          <div id="wrapPrint" class="wrap_print">
            <div class="page_print">
              <div class="tbl_print tbl_list">
                <table>
                  <colgroup>
                    <!-- <col style="width: 180px;">
                    <col style="width: 144px;">
                    <col style="width: 184px;">
                    <col style="width: 220px;"> -->
                    <col style="width: 210px" />
                    <col style="width: 184px" />
                    <col style="width: 170px" />
                    <col style="width: 170px" />
                    <col style="width: 130px" />
                    <col style="width: 130px" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>계약 기간</th>
                      <th>파트너사 명</th>
                      <th>최종계약금액</th>
                      <th>금번검수금액</th>
                      <th>납품완료일</th>
                      <th>검수완료일</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ dateRange }}</td>
                      <td>{{ companyName }}</td>
                      <td>{{ finalPrice }} {{ inspectData.currencyCode }}</td>
                      <td>{{ inspectPrice }} {{ inspectData.currencyCode }}</td>
                      <td>{{ inspectData.deliveryCompleteDate || "-" }}</td>
                      <td>{{ inspectData.inspectCompleteDate || "-" }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-if="!isInitial" class="tbl_print tbl_view">
                <table>
                  <colgroup>
                    <col style="width: 192px" />
                    <col />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>납품내역</th>
                      <td>{{ inspectData.deliveryHistory }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="tbl_print tbl_list">
                <table>
                  <colgroup>
                    <col style="width: 157px" />
                    <!-- 일시 -->
                    <col style="width: 130px" />
                    <!-- 상태 -->
                    <col style="width: 357px" />
                    <!-- 의견 -->
                    <col style="width: 135px" />
                    <!-- 담당자 -->
                    <col style="width: 215px" />
                    <!-- 첨부파일 -->
                  </colgroup>
                  <thead>
                    <tr>
                      <th>일시</th>
                      <th>상태</th>
                      <th>의견</th>
                      <th>담당자</th>
                      <th>첨부파일</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in histories" :key="index">
                      <td class="align_top">
                        {{ regDateWithTime(item.regDate) }}
                      </td>
                      <td class="align_top">
                        {{ stateCodeName(item.stateCodeName, item.stateCode) }}
                      </td>
                      <td class="align_top align_left" v-html="item.etc || '-'" />
                      <td class="align_top">
                        {{ item.regId || "-" }}
                      </td>
                      <td>
                        <FileView :dataList="item.inspectFile ? item.inspectFile : []" />
                      </td>
                    </tr>
                    <tr v-if="histories.length < 1">
                      <td colspan="6" class="td_empty">검색 결과가 없습니다</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Sticky class="sticky">
      <button type="button" class="btn_fourthly btn_large" @click="onClickClose">닫기</button>
      <button type="submit" class="btn_tertiary btn_large" @click="print">인쇄</button>
    </Sticky>
  </Popup>
</template>

<script>
import Popup from "@/components/layout/popup/Popup";
import Sticky from "@/components/layout/content/Sticky.vue";

import Board from "@/components/common/board/Board";
import FileView from "@/components/common/file/FileView";

import ApiService from "@/services/ApiService";

import inspectStatusList from "@/constants/inspectStatusList";
import { getDateString } from "@/utils/dateUtils";
import { addCommaForMoney } from "@/utils/stringUtils";
import { getDateStringWithTime } from "@/utils/dateUtils";

import { Printd } from "printd";
import printcss from "@/constants/printcss.js";

import InspectContractInfoMixin from "./InspectContractInfoMixin";

export default {
  name: "InspectPrintPopup",
  components: {
    Popup,
    Sticky,
    Board,
    FileView,
  },
  mixins: [InspectContractInfoMixin],
  computed: {
    histories() {
      const { contractInspectHistoryList } = this.inspectData;

      return contractInspectHistoryList || [];
    },
    isInitial() {
      //현업검수일 경우
      const { stateCode } = this.lastestHistory;

      return stateCode == "INITIAL";
    },
    lastestHistory() {
      if (this.histories.length === 0) return null;

      // 맨 뒤가 현재 상태로 변경.
      // return this.histories[ 0 ];
      return this.histories[this.histories.length - 1];
    },
  },
  methods: {
    print() {
      const wrapPrint = document.getElementById("wrapPrint");
      const d = new Printd();

      // printd 라이브러리 버그로 인해 그냥 스트링을 가져오는 걸로 임시 처리. 추후 변경 필요.
      // 버그 내용: css를 읽었다 안읽었다 한다.
      // const styles = [
      //   '/assets/css/print.css'
      // ]

      const styles = [printcss];

      d.print(wrapPrint, styles);
    },
    regDateWithTime(regDate) {
      // const { regDate } = this.item;
      if (!regDate) return "";
      // return regDate;
      return getDateStringWithTime(regDate);
    },
    stateCodeName(stateCodeName, stateCode) {
      // const { stateCodeName, stateCode } = this.item;
      if (stateCodeName) return stateCodeName;

      const result = inspectStatusList.find((item) => item.code === stateCode);
      if (!result) return "";

      return result.text;
    },
    onClickClose() {
      this.$emit("onClickClose");
    },
  },
};
</script>
<style scoped>
@import "~@/assets/css/print.css";
.page_print + .page_print {
  border-top: 1px solid #e1e1e1;
}
</style>
