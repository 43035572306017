<template>
  <tr>
    <!-- No -->
    <td class="align_right">{{ index + 1 }}</td>
    <!-- 품명 -->
    <td class="align_left">
      {{ item.itemName }}
    </td>
    <!-- 품목코드 -->
    <td>
      <button
        :class="['link_subject']"
        @click="$windowOpen(`${$routerPath.ITEM_VIEW}/${item.itemCode}`)"
      >
        {{ item.displayCode }}
      </button>
    </td>
    <!-- 자산코드 -->
    <td>
      {{ item.assetsCode | nullToDash }}
    </td>
    <!-- 단위 -->
    <td>
      {{ item.unitValue }}
    </td>
    <!-- 수량 -->
    <td class="align_right" :data-ellip="`단가 : ${computedEllipPrice}`">
      {{ item.quantity }}
    </td>
    <!-- 예산금액 -->
    <td class="align_right">
      {{ item.totalPrice | currency }}
    </td>
    <!-- 소비자가 -->
    <td class="align_right">
      {{ item.marketPrice | currency }}
    </td>
    <!-- 계약금액 -->
    <td class="align_right" :data-ellip="`단가 : ${computedEllipContractPrice}`">
      {{ item.contractPrice | currency }}
    </td>
    <!-- 할인율(%) -->
    <td class="align_right">
      {{ item.discountRate }}
    </td>
    <!-- 부가세 -->
    <td class="align_right">
      {{ item.vat | currency }}
    </td>
    <!-- 절감액 -->
    <td class="align_right">
      {{ item.savingAmount | currency }}
    </td>
    <!-- 절감율(%) -->
    <td class="align_right">
      {{ item.savingsRate }}
    </td>
    <!-- 품목상세 -->
    <td class="align_left">
      {{ item.note }}
    </td>
  </tr>
</template>

<script>
import DialougeItem from "@/components/common/dialouge/DialougeItem";
import Input from "@/components/common/input/Input";
import InputAutoMoney from "@/components/common/input/InputAutoMoney";
import InputRate from "@/components/common/input/InputRate";

import SelectBoxQuantityIncludeData from "@/components/common/selectBox/SelectBoxQuantityIncludeData";
import SelectBox from "@/components/common/selectBox/SelectBox";

import { addCommaForMoney, getMoneyToNumber } from "@/utils/stringUtils";
import { ALERT_ACTION } from "@/store/modules/alert/action";

export default {
  name: "SupplyInfoLine",
  components: {
    DialougeItem,
    Input,
    InputAutoMoney,
    InputRate,
    SelectBoxQuantityIncludeData,
    SelectBox,
  },
  props: {
    item: Object,
    index: Number,
  },
  computed: {
    computedEllipPrice() {
      return addCommaForMoney(
        _.floor(getMoneyToNumber(this.item.totalPrice) / Number(this.item.quantity)),
      );
    },
    computedEllipContractPrice() {
      return addCommaForMoney(
        _.floor(getMoneyToNumber(this.item.contractPrice) / getMoneyToNumber(this.item.quantity)),
      );
    },
  },
  watch: {
    // 'item.quantity': function (newVal, oldVal) {
    //   this.item.changeCalcPrice(newVal);
    // },
    // 'item.price': function (newVal, oldVal) {
    //   this.item.changeCalcPrice(newVal);
    // },
    // 'item.totalPrice'(newVal, oldVal) {
    //   this.$emit('changePrice', this.index);
    // },
    // 'item.marketPrice': function (newVal, oldVal) {
    //   const { contractPrice } = this.item;
    //   if (contractPrice) {
    //     //할인율 계산
    //     const discountRate = this.calcDiscountRate(contractPrice, newVal);
    //     this.item.discountRate = String(discountRate);
    //   }
    // },
    // 'item.contractPrice': function (newVal, oldVal) {
    //   const { totalPrice, marketPrice } = this.item;
    //   if (marketPrice) {
    //     //할인율 계산
    //     const discountRate = this.calcDiscountRate(newVal, marketPrice);
    //     this.item.discountRate = String(discountRate);
    //   }
    //   if (totalPrice) {
    //     //절감액,절감율
    //     const savingAmount = this.calcSavings(newVal, totalPrice);
    //     this.item.savingAmount = String(savingAmount);
    //     const savingsRate = this.calcSavingsRate(savingAmount, totalPrice);
    //     this.item.savingsRate = String(savingsRate);
    //   }
    //   //부가세 계산
    //   this.item.vat = String(this.calcVat(newVal));
    //   this.$emit('changePrice', this.index);
    // },
  },
  methods: {
    // calcDiscountRate(contractPrice, marketPrice) {
    //   //할인율계산
    //   const discountRate = (getMoneyToNumber(contractPrice) / getMoneyToNumber(marketPrice)) * 100;
    //   if (discountRate >= 100) {
    //     // this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, {
    //     //   text: `할인율이 100을 초과할수 없습니다.`,
    //     // });
    //     // return;
    //   }
    //   return discountRate;
    // },
    // calcVat(contractPrice) {
    //   //부가세계산
    //   const vat = getMoneyToNumber(contractPrice) * 0.1;
    //   return Math.round(vat);
    // },
    // calcSavings(contractPrice, totalPrice) {
    //   //절감액
    //   const savingAmount = getMoneyToNumber(totalPrice) - getMoneyToNumber(contractPrice);
    //   if (savingAmount < 0) {
    //     this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, {
    //       text: `계약금액이 예산금액보다 클수 없습니다.`,
    //     });
    //     return;
    //   }
    //   return savingAmount;
    // },
    // calcSavingsRate(savingAmount, totalPrice) {
    //   //절감율계산
    //   const savingsRate = (getMoneyToNumber(savingAmount) / getMoneyToNumber(totalPrice)) * 100;
    //   if (savingsRate >= 100) {
    //     this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, {
    //       text: `절감율이 100을 초과할수 없습니다.`,
    //     });
    //     return;
    //   }
    //   return Math.round(savingsRate);
    // },
  },
};
</script>
<style lang="scss" scoped>
.group_form {
  display: flex;
  & > .dialogue_comm {
    display: block;
    width: 100%;
  }
}
</style>
