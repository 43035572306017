<!--
0 - 전체
P - 작성중(임시저장) > 어드민만 작성한거
I - 전자서명요청 > 어드민이 > 파트너한테 보낸거
G - 파트너 전자서명 완료
C - 계약체결완료
M - 중도종결
F - 계약완전종료(검수끝난거)
N - 어드민 계약취소
-->
<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 class="tit_section">
          계약 관리
          <span v-if="itemData.contractCid" class="txt_view">{{ itemData.contractCid }}</span>
        </h2>
      </div>
      <div class="body_section">
        <ContractSetting
          :performanceUseYn="performanceUseYn"
          :itemData.sync="itemData"
          :templateName="templateName"
          :purchaseWfContract.sync="itemData.purchaseWfContract"
          :estimateContract="itemData.estimateContract"
          :contractPayment.sync="itemData.contractPayment"
          :orderPayment.sync="itemData.orderPayment"
          @onClickConnectPurchaseWfPost="onClickConnectPurchaseWfPost"
          @onClickConnectEstimateWfPost="onClickConnectEstimateWfPost"
          @onClickPopEstimatePrint="onClickPopEstimatePrint"
        />

        <ManagerInfo
          v-if="itemData.contractManagerList"
          :managerData.sync="managerData"
          :contractManagerList="itemData.contractManagerList"
        />

        <SupplyPartnerInfo
          v-if="itemData.contractPerson"
          :contractPerson.sync="itemData.contractPerson"
        />
        <SupplyPartnerManagerInfoInput
          v-if="itemData.contractPerson"
          :contractPerson.sync="itemData.contractPerson"
        />

        <SupplyInfo
          v-if="itemData.orderGoodsAddInfo"
          :itemData="itemData"
          :orderGoodsList.sync="itemData.orderGoodsList"
          :orderInspectedGoodsList="itemData.orderInspectedGoodsList || []"
          :totalInspectAmount="itemData.totalInspectAmount || ''"
          :orderGoodsAddInfo.sync="itemData.orderGoodsAddInfo"
          :attachFile.sync="attachFile"
        />

        <PaymentMethod
          v-if="itemData.orderPayment"
          :orderPayment.sync="itemData.orderPayment"
          :attachFile.sync="attachFile"
        />

        <AddInfo
          v-if="itemData.orderGoodsAddInfo"
          :itemData.sync="itemData"
          :attachFile.sync="attachFile"
        />

        <template v-if="itemData.contractAgreeHistory && itemData.contractAgreeHistory.length > 0">
          <TableViewWithTitle title="계약내역">
            <template v-slot:colgroup>
              <!-- 동의여부 -->
              <col style="width: 80px" />
              <!-- 날짜 -->
              <col style="width: 160px" />
              <!-- 사유 -->
              <col />
            </template>
            <template v-slot:header>
              <th scope="col">동의여부</th>
              <th scope="col">날짜</th>
              <th scope="col">사유</th>
            </template>
            <template v-slot:body>
              <tr v-for="item in itemData.contractAgreeHistory" :key="item.agreeNum">
                <td :class="{ tc_red: !item.agreeYn }">
                  {{ item.agreeYn ? "동의" : "미동의" }}
                </td>
                <td class="align_center">{{ item.regDate | dateTimeStringFormat }}</td>
                <td class="td_preline" :class="{ tc_red: !item.agreeYn }">
                  {{ item.disagreeReason | nullToDash }}
                </td>
              </tr>
            </template>
          </TableViewWithTitle>
        </template>

        <template v-if="contractStatus === 'C'">
          <TableViewWithTitle title="중도종결 사유">
            <template v-slot:body>
              <tr>
                <th>중도종결 사유<span class="ico_purchase ico_star">필수항목</span></th>
                <td colspan="3">
                  <Textarea
                    :value.sync="changeReason"
                    :maxLength="300"
                    :isCount="true"
                    placeholder="사유를 입력하세요."
                  />
                </td>
              </tr>
            </template>
          </TableViewWithTitle>
        </template>
        <template v-if="itemData.changeReason">
          <TableViewWithTitle title="중도종결 사유">
            <template v-slot:body>
              <tr>
                <th>중도종결 사유</th>
                <td colspan="3" class="list_total td_preline">
                  {{ itemData.changeReason }}
                </td>
              </tr>
            </template>
          </TableViewWithTitle>
        </template>
      </div>
    </div>
    <Sticky>
      <div class="area_left">
        <button class="btn_fourthly btn_large" @click="onClickGoToList">목록</button>
        <template v-if="contractStatus === 'G' || contractStatus === 'C'">
          <button
            class="btn_primary btn_large"
            :disabled="!isMydraft"
            @click="
              () => {
                $router.push(`${computedPaymentPath}/${aprvlInfo.docNo}`);
              }
            "
          >
            전자결재 - {{ aprvlInfo.docStatusName }}
          </button>
          <template v-if="!aprvlInfo.docNo">
            <button class="btn_primary btn_large" @click="onClickPdfRegen">전재결재 재생성</button>
          </template>
        </template>
        <template v-if="$isAdmin && contractStatus === 'I'">
          <button class="btn_fifthly btn_large" @click="onClickWithdraw">회수</button>
        </template>
      </div>
      <div class="area_right">
        <template v-if="isUser">
          <template
            v-if="contractStatus === 'P' || contractStatus === 'I' || contractStatus === 'F'"
          >
            <button class="btn_fourthly btn_large" @click="onClickPreviewPop">미리보기</button>
          </template>
          <template v-else>
            <button class="btn_fourthly btn_large" @click="onClickContractPdfView">미리보기</button>
          </template>
        </template>

        <template v-if="$isAdmin">
          <button
            v-if="contractStatus === 'P'"
            class="btn_fifthly btn_large"
            @click="onClickDelete"
          >
            삭제
          </button>

          <template v-if="contractStatus === 'P'">
            <button class="btn_fourthly btn_large" @click="onClickPreviewPop">미리보기</button>
          </template>
          <template v-else>
            <button class="btn_fourthly btn_large" @click="onClickContractPdfView">미리보기</button>
          </template>

          <button
            v-if="contractStatus === 'P'"
            class="btn_fourthly btn_large"
            @click="onClickModify"
          >
            수정
          </button>
          <template v-if="!offlineYn">
            <button
              v-if="contractStatus === 'P'"
              class="btn_primary btn_large"
              @click="onClickConfirm"
            >
              전자서명 요청
            </button>
          </template>
          <template v-else>
            <!-- offline 계약-->
            <button
              v-if="contractStatus === 'P'"
              class="btn_primary btn_large"
              @click="onClickOfflineConfirm"
            >
              전자서명 요청
            </button>
          </template>

          <button
            v-if="contractStatus === 'C'"
            class="btn_primary btn_large"
            @click="onClickContractMiddleClose"
          >
            중도종결
          </button>

          <button
            v-if="contractStatus === 'C'"
            class="btn_primary btn_large"
            @click="onClickChangeContract"
          >
            변경계약
          </button>
        </template>
      </div>
    </Sticky>
    <template v-slot:popup>
      <PDF
        v-show="false"
        ref="pdfCheck"
        :src="pdfViewSrc"
        @error="pdfCheckError"
        @loaded="pdfCheckLoaded"
      ></PDF>
      <PartnerInspectPopup
        v-if="isPopPartnerInspect"
        :inspectData="inspectData"
        @onClickClose="onClickClosePartnerInspect"
      />

      <InspectConfirmPopup
        v-if="isPopInspect"
        :inspectData="inspectData"
        :calculateNum="calculateNumSelected"
        :contractId="contractIdSelected"
        :iManager="contractManagerListExceptBManager"
        @onInspectSuccess="onInspectSuccess"
        @onClickClose="onClickCloseInspect"
        @onClickPrintPreview="onClickPrintPreview"
        @onClickReject="onClickReject"
        @alert="alert"
      />
      <InspectRejectPopup
        v-if="isPopInspectReject"
        @onClickClose="onClickCloseReject"
        @onClickSubmit="onClickSubmitReject"
        @alert="alert"
      />
      <InspectPrintPopup
        v-if="isPopInspectPrint"
        :inspectData="inspectData"
        :calculateNum="calculateNumSelected"
        :contractId="contractIdSelected"
        @onClickClose="onClickCloseInspectPrint"
      />
      <PerformanceEvaluatePopup
        v-if="isPopPerformanceEvaluate"
        :isEvaluated="false"
        :isInspect="isInspect"
        :itemId="itemId"
        :rowData="evaluatePopupData"
        @onClickSubmitAction="onClickSubmitEvaluate"
        @onClickClose="onClickClosePerformanceEvaluate"
      />
      <AlertPopup
        v-if="isDeletePopupMode"
        alertText="해당 계약을 삭제 하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickClosePopDelete"
        @onClickPrimary="onClickConfirmPopDelete"
      />
      <TemplatePreviewPopup
        v-if="isTemplatePopup"
        :previewId="previewId"
        @onClickClose="onClickClosePreviewPopup"
      />
      <EstimatePrintPopup
        v-if="isPopEstimatePrint"
        :estimateNo="estimatePrint ? estimatePrint.quotationNum : null"
        :onlyCloseButton="true"
        @onClickClose="onClickClosePopEstimatePrint"
      />
    </template>
  </PageWithLayout>
</template>

<script>
import PDF from "vue-pdf";
import PageWithLayout from "@/components/layout/PageWithLayout";
import Sticky from "@/components/layout/content/Sticky.vue";
import Textarea from "@/components/common/textarea/Textarea";
import AlertPopup from "@/components/layout/popup/AlertPopup";
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import ContractSetting from "@/components/admin/contract/view/ContractSetting";
// import PurchaseJiraConnectView from '@/components/shared/purchaseJira/PurchaseJiraConnectView'
// import PurchaseReqeust from '@/components/admin/contract/view/PurchaseReqeust'
import ContractInfo from "@/components/admin/contract/view/ContractInfo";
import PaymentMethod from "@/components/admin/contract/view/PaymentMethod";
import AddPaymentMethod from "@/components/admin/contract/view/AddPaymentMethod";
import SpecialAgreement from "@/components/admin/contract/view/SpecialAgreement";
import ManagerInfo from "@/components/admin/contract/view/ManagerInfo";
import SupplyPartnerInfo from "@/components/admin/contract/view/SupplyPartnerInfo";
import SupplyPartnerManagerInfoInput from "@/components/admin/contract/view/SupplyPartnerManagerInfoInput";

import SupplyInfo from "@/components/admin/contract/view/SupplyInfo";

import ContractCondition from "@/components/admin/contract/view/ContractCondition";
import SettleUnitCost from "@/components/admin/contract/view/SettleUnitCost";
import SettleInfo from "@/components/admin/contract/view/SettleInfo";
import AddInfo from "@/components/admin/contract/view/AddInfo.vue";
import PaymentInfo from "@/components/admin/contract/view/PaymentInfo";

import PartnerInspectPopup from "@/components/admin/contract/popup/PartnerInspectPopup";
import InspectConfirmPopup from "@/components/admin/contract/popup/InspectConfirmPopup";
import InspectRejectPopup from "@/components/admin/contract/popup/InspectRejectPopup";
import InspectPrintPopup from "@/components/admin/contract/popup/InspectPrintPopup";
import PerformanceEvaluatePopup from "@/components/shared/performanceEvaluate/PerformanceEvaluatePopup";
import TemplatePreviewPopup from "@/components/shared/template/TemplatePreviewPopup.vue";
import EstimatePrintPopup from "@/components/admin/estimate/popup/EstimatePrintPopup";

// import PurchaseRequestJiraDetailPopup from '@/components/admin/purchaseRequest/popup/PurchaseRequestJiraDetailPopup'
import { ALERT_ACTION } from "@/store/modules/alert/action";

import PageMixin from "@/mixins/PageMixin";

import ApiService from "@/services/ApiService";
import LocalStorageManager from "@/LocalStorageManager";
import { ObjectURLPdf } from "@/utils/pdfUtils";
import { downloadFile } from "@/utils/fileUtils";
import { downloadPdf } from "@/utils/pdfUtils";
import { downloadZip } from "@/utils/zipUtils";
import { getMoneyToKorean } from "@/utils/stringUtils";
import { getPaymentRouterPath } from "@/utils/paymentPathUtils";
import { escapeRegExp } from "@/utils/spUtils";

export default {
  name: "ContractInfoView",
  components: {
    PDF,
    PageWithLayout,
    Textarea,
    Sticky,
    AlertPopup,
    TableViewWithTitle,

    ContractSetting,
    // PurchaseJiraConnectView,
    // PurchaseReqeust,
    // PurchaseRequestJiraDetailPopup,
    ContractInfo,
    PaymentMethod,
    AddPaymentMethod,
    SpecialAgreement,
    ManagerInfo,
    SupplyPartnerInfo,
    SupplyPartnerManagerInfoInput,
    SupplyInfo,

    ContractCondition,
    SettleUnitCost,
    SettleInfo,
    PaymentInfo,
    AddInfo,
    PartnerInspectPopup,
    InspectConfirmPopup,
    InspectRejectPopup,
    InspectPrintPopup,
    PerformanceEvaluatePopup,
    TemplatePreviewPopup,
    EstimatePrintPopup,
  },

  mixins: [PageMixin],
  data() {
    return {
      itemData: {},
      attachFile: {
        electronicDocumentFile: [], //전자서명파일
        purchaseOrderFile: [], //발주서
        estimateFile: [], //견적서
        contractFile: [], //계약서
        etcAttachFile: [], //기타파일
        advancePaymentSuretyAttachFile: [], //선급금이행증서
        contractSuretyAttachFile: [], //계약이행증서
        defectSuretyAttachFile: [], //하자이행증권
      },

      signUseYn: "Y",
      isSignUseYn: false,
      performanceUseYn: "N",
      normalYn: "Y",

      rejectPayload: null,

      isPopPartnerInspect: false,

      isPopInspect: false,
      isDeletePopupMode: false,
      isPopInspectReject: false,
      isPopInspectPrint: false,

      isPopPerformanceEvaluate: false,
      isPopEstimatePrint: false,

      calculateNumSelected: null,
      contractIdSelected: null,
      inspectData: {}, // 검수 팝업.

      pEvaluate: false,
      pRequestEvaluate: false,
      iEvaluate: false,
      requestSign: false,
      requestReview: false,
      reviewProcessiong: false,
      evaluatePopupData: {},
      isTemplatePopup: false,
      templateId: "",
      templateName: "",
      afterRequestSign: false,
      showDownload: false,
      showModify: false,
      showDelete: false,
      contractStatus: "",
      resultId: "",

      selectedCompany: "",
      previewId: "",

      changeReason: "", //중도종결 사유

      canDownloadRequest: false,
      estimatePrint: null,

      postCid: "",
      aprvlInfo: {
        docStatus: "",
        docStatusName: "",
        docNo: "",
      },

      pdfViewSrc: null,
      offlineYn: false,
    };
  },
  computed: {
    isMydraft() {
      const { username } = LocalStorageManager.shared.getUserData();
      const { managerId } = this.itemData;

      return username === managerId;
    },
    isInspect() {
      return LocalStorageManager.shared.getIsInspect();
    },
    isUser() {
      return LocalStorageManager.shared.getIsUser();
    },

    contractManagerListExceptBManager() {
      const { contractManagerList } = this.itemData;
      const { contractInspectHistoryList } = this.inspectData;
      if (!contractManagerList) return [];

      const ret = contractManagerList.filter((item) => item.code !== "B");

      return ret;
    },
    paymentsData() {
      //console.log(thi);
      const { path } = this.$route;
      const title = this.itemData.postTitle;

      let ret = {
        path,
        title,
      };

      return ret;
    },
    managerData() {
      const { managerId, managerName, managerNum, managerEmail, managerTel } = this.itemData;
      const ret = { managerId, managerName, managerNum, managerEmail, managerTel };
      return ret;
    },
    computedPaymentPath() {
      const { docStatus } = this.aprvlInfo;
      const ret = getPaymentRouterPath(docStatus);
      return ret;
    },
    ingPayment() {
      const { docStatus, docStatusName, docNo } = this.aprvlInfo;

      let ret = true;
      if (docStatus) {
        //전자결제 문서가 있을떄
        ret = true;
      } else {
        //전자결재 문서 안만들었을떄

        ret = false;
      }

      return ret;
    },
  },
  created() {
    this.itemId = this.$route.params.id;

    this.getData(this.itemId);

    // import("pdfjs-dist").then((pdfjsLib) => {
    //   pdfjsLib.GlobalWorkerOptions.workerSrc =
    //     "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.7.570/build/pdf.worker.min.js";
    //   this.pdfjsLib = pdfjsLib;
    //   // pdfjsLib.getDocument("/dummy.pdf").promise.then(function (pdf) {
    //   //   pdf.getPage(1).then((page) => {
    //   //     console.log(page);
    //   //   });
    //   // });
    // });
  },
  methods: {
    async getData(itemId) {
      const path = `${this.$apiPath.CONTRACT}/${itemId}`;

      const result = await ApiService.shared.getData(path);
      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }

      this.itemData = data;
      // this.contractStatus = result.data.contractStatus;

      this.setUseOrNot(this.itemData);

      if (this.itemData.templateId) {
        this.templateId = this.itemData.templateId;
      }
      if (this.itemData.templateName) {
        this.templateName = this.itemData.templateName;
      }
      if (this.itemData.contractStatus) {
        this.contractStatus = this.itemData.contractStatus;
      }
      if (this.itemData.resultId) {
        this.resultId = this.itemData.resultId;
      }
      this.attachFile = { ...this.attachFile, ...this.itemData.attachFile };
      if (this.itemData.aprvlInfo) {
        this.aprvlInfo = this.itemData.aprvlInfo;
      }
      if (this.itemData.changeReason) {
        this.changeReason = this.itemData.changeReason;
      }

      this.offlineYn = this.itemData.offlineYn;
    },
    setUseOrNot(obj) {
      this.signUseYn =
        obj.contractSetting.signatureYn == "" ? "N" : obj.contractSetting.signatureYn;
      // TODO :  표준화제거
      //if (this.signUseYn == 'Y') this.isSignUseYn = true;
      if (this.signUseYn == "Y") this.isSignUseYn = false;
      // normalYn = Y : 기본 템플릿 ( 일반 ) /  N : 별도 템플릿
      this.normalYn = obj.contractSetting.normalYn == "" ? "Y" : obj.contractSetting.normalYn;

      if (!this.isInspect) this.pEvaluateBtn(obj);
      if (this.isInspect) this.iEvaluateBtn(obj);

      this.showBtns(obj);

      this.performanceUseYn = obj.contractSetting.evaluateYn;
    },
    onClickConnectPurchaseWfPost(item) {
      this.$windowOpen(`${this.$routerPath.PURCHASE_VIEW}/${item.requestCid}`);
    },
    onClickConnectEstimateWfPost(item) {
      this.$windowOpen(`${this.$routerPath.ESTIMATE_VIEW}/${item.estimateNum}`);
    },
    onClickInspect(calculateNum, contractId) {
      this.calculateNumSelected = calculateNum;
      this.contractIdSelected = contractId;

      const contractList = this.itemData.contractCalculateList;

      //수행도평가 진행 안했을때는 마지막 검수 못하도록 막기.
      // n-1회차 검수직전 수행도평가 진행 안했을때는 검수 못하도록 막기. ( 일반계약만 해당 )

      // 현업 담당자 로그인 >
      // 마지막 회차 데이터의 검수 상태가 납품대기 ( 검수(현업) 전 ) && 선택한 검수데이터만 알럿창 띄워주기 위해 calculateNum 비교 ( 검수확인창을 띄워줘야하는 경우 때문에)
      // 현업담당자 수행도평가 전일때 ( evaluateStatus == "I" )
      if (this.iEvaluate && this.itemData.category.indexOf("인프라") == -1) {
        const num = this.itemData.contractCalculateList.length - 1;
        if (
          this.itemData.contractCalculateList[num].stateCode == "INITIAL" &&
          this.itemData.contractCalculateList[num].calculateNum == calculateNum
        ) {
          if (this.itemData.evaluateStatus == "I") {
            this.alert("수행도평가를 먼저 진행해주세요");

            return;
          }
        }
      }

      // 구매 담당자 로그인 >
      // 마지막 회차 데이터의 검수 상태가 납품대기 ( 검수(현업) 전 ) || 검수대기(구매) ) 이고 - > 즉, 구매담당자 검수 전
      // 선택한 검수데이터만 알럿창 띄워주기 위해 calculateNum 비교 ( 검수확인창을 띄워줘야하는 경우 때문에)
      // 현업담당자 수행도평가 전일때 ( evaluateStatus == "I" )
      if (this.pEvaluate && this.itemData.category.indexOf("인프라") == -1) {
        var num = this.itemData.contractCalculateList.length - 1;
        if (
          (this.itemData.contractCalculateList[num].stateCode == "INITIAL" ||
            this.itemData.contractCalculateList[num].stateCode == "REQUESTER_INSPECT") &&
          this.itemData.contractCalculateList[num].calculateNum == calculateNum
        ) {
          if (this.itemData.evaluateStatus != "C") {
            this.alert("수행도평가를 먼저 진행해주세요");

            return;
          }
        }
      }

      this.getHistory(calculateNum, contractId, false);
    },
    onClickPartnerInspect(calculateNum, contractId) {
      this.calculateNumSelected = calculateNum;
      this.contractIdSelected = contractId;

      const contractList = this.itemData.contractCalculateList;

      //수행도평가 진행 안했을때는 마지막 검수 못하도록 막기.
      // n-1회차 검수직전 수행도평가 진행 안했을때는 검수 못하도록 막기. ( 일반계약만 해당 )

      // 현업 담당자 로그인 >
      // 마지막 회차 데이터의 검수 상태가 납품대기 ( 검수(현업) 전 ) && 선택한 검수데이터만 알럿창 띄워주기 위해 calculateNum 비교 ( 검수확인창을 띄워줘야하는 경우 때문에)
      // 현업담당자 수행도평가 전일때 ( evaluateStatus == "I" )
      if (this.iEvaluate && this.itemData.category.indexOf("인프라") == -1) {
        const num = this.itemData.contractCalculateList.length - 1;
        if (
          this.itemData.contractCalculateList[num].stateCode == "INITIAL" &&
          this.itemData.contractCalculateList[num].calculateNum == calculateNum
        ) {
          if (this.itemData.evaluateStatus == "I") {
            this.alert("수행도평가를 먼저 진행해주세요");

            return;
          }
        }
      }

      // 구매 담당자 로그인 >
      // 마지막 회차 데이터의 검수 상태가 납품대기 ( 검수(현업) 전 ) || 검수대기(구매) ) 이고 - > 즉, 구매담당자 검수 전
      // 선택한 검수데이터만 알럿창 띄워주기 위해 calculateNum 비교 ( 검수확인창을 띄워줘야하는 경우 때문에)
      // 현업담당자 수행도평가 전일때 ( evaluateStatus == "I" )
      if (this.pEvaluate && this.itemData.category.indexOf("인프라") == -1) {
        var num = this.itemData.contractCalculateList.length - 1;
        if (
          (this.itemData.contractCalculateList[num].stateCode == "INITIAL" ||
            this.itemData.contractCalculateList[num].stateCode == "REQUESTER_INSPECT") &&
          this.itemData.contractCalculateList[num].calculateNum == calculateNum
        ) {
          if (this.itemData.evaluateStatus != "C") {
            this.alert("수행도평가를 먼저 진행해주세요");

            return;
          }
        }
      }

      this.getHistory(calculateNum, contractId, true);
    },
    async getHistory(calculateNum, contractId, isPartnerInspect) {
      const path = `${this.$apiPath.INSPECT}?calculateNum=${calculateNum}&contractId=${contractId}`;

      const result = await ApiService.shared.getData(path);

      if (!result.data) {
        // this.dataList = [];
        this.alert(result.text);
        return;
      }

      this.inspectData = result.data;

      if (isPartnerInspect) {
        this.isPopPartnerInspect = true;
      } else {
        this.isPopInspect = true;
      }

      // this.dataList = result.data.contractInspectHistoryList || [];
      // this.cancelYn = result.data.cancelYn || false;
    },
    onClickPerformanceEvaluate() {
      this.getEvaluatePopupData();

      //this.isPopPerformanceEvaluate = true;
    },
    onClickClosePerformanceEvaluate() {
      this.isPopPerformanceEvaluate = false;
    },
    onClickReject(payload) {
      this.rejectPayload = payload;

      this.isPopInspectReject = true;
      this.isPopInspect = false;
    },
    onClickCloseReject() {
      this.isPopInspectReject = false;
      this.isPopInspect = true;
    },
    onClickSubmitReject(text) {
      this.rejectInspect(text);
    },
    // onClickCloseDetail(){
    //   this.requestCidSelected = '';
    //   // this.isPopDetailJira = false;
    // },
    // onClickDetailJira( id ){
    //   this.requestCidSelected = id;
    //   // this.isPopDetailJira = true;
    // },

    onClickClosePopEstimatePrint() {
      this.isPopEstimatePrint = false;
    },
    async onClickPopEstimatePrint(item) {
      const { estimateNum } = item;

      const quotationPath = `${this.$apiPath.QUOTATION}/selection?estimateNum=${estimateNum}`;
      const quotationResult = await ApiService.shared.getData(quotationPath);
      const { quotationNum } = quotationResult.data[0];

      const path = `${this.$apiPath.QUOTATION}/${quotationNum}`;
      const result = await ApiService.shared.getData(path);
      const { code, data, text } = result;

      this.estimatePrint = data;
      this.isPopEstimatePrint = true;
    },

    onAlert(text) {
      this.alert(text);
    },
    async rejectInspect(text) {
      const path = `${this.$apiPath.INSPECT}/${this.contractIdSelected}/reject/${this.calculateNumSelected}`;

      let obj = {
        etc: text,
      };

      if (this.rejectPayload) {
        const { deliveryHistory, inspectCompleteDate, deliveryCompleteDate } = this.rejectPayload;

        if (deliveryHistory) obj.deliveryHistory = deliveryHistory;
        if (inspectCompleteDate) obj.inspectCompleteDate = inspectCompleteDate;
        if (deliveryCompleteDate) obj.deliveryCompleteDate = deliveryCompleteDate;
      }

      const result = await ApiService.shared.putData(path, obj);

      this.isPopInspectReject = false;
      this.isPopInspect = false;

      if (result.code !== "200") {
        this.$emit("alert", result.text);
        return;
      }

      this.onInspectSuccess(this.contractId);
    },
    onClickPrintPreview() {
      this.isPopInspectPrint = true;
      //this.isPopInspect = false;
    },
    onClickGoToList() {
      this.$router.push({
        path: this.$routerPath.CONTRACT_LIST,
        query: this.$route.query,
      });
    },
    onClickModify() {
      this.$router.push({
        path: `${this.$routerPath.CONTRACT_MODIFY}/${this.itemId}`,
      });
    },
    onClickDelete() {
      this.isDeletePopupMode = true;
    },
    onClickConfirmPopDelete() {
      this.deleteItem(this.itemId);
    },
    onClickClosePopDelete() {
      this.isDeletePopupMode = false;
    },
    async deleteItem(contractId) {
      const path = `${this.$apiPath.CONTRACT}/${contractId}`;
      const result = await ApiService.shared.deleteData(path);

      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }

      this.isDeletePopupMode = false;

      this.$router.push({
        path: this.$routerPath.CONTRACT_LIST,
      });
    },
    onClickClosePartnerInspect() {
      this.isPopPartnerInspect = false;

      this.calculateNumSelected = null;
      this.contractIdSelected = null;
    },
    onClickCloseInspect() {
      this.isPopInspect = false;

      this.calculateNumSelected = null;
      this.contractIdSelected = null;
    },
    onClickCloseInspectPrint(data) {
      this.isPopInspectPrint = false;
      this.isPopInspect = true;
    },
    onInspectSuccess(contractId) {
      this.isPopInspect = false;

      this.getData(this.itemId);
    },
    onClickSubmitEvaluate(obj) {
      this.submitEvaluate(obj);
    },
    async submitEvaluate(obj) {
      const path = `${this.$apiPath.EVALUATE}/${this.itemId}`;

      const result = await ApiService.shared.postData(path, obj);

      if (result.code !== "200") {
        this.alert(result.text);

        return;
      }

      this.alert("평가가 완료되었습니다.");

      this.isPopPerformanceEvaluate = false;

      if (!this.isInspect) {
        this.pEvaluate = false;
      } else {
        this.iEvaluate = false;
      }

      this.itemId = this.$route.params.id;

      this.getData(this.itemId);
    },
    pEvaluateBtn(obj) {
      // 수행도평가 요청 버튼 노출 조건 - 구매 담당자 (인프라)
      //  1) 검수데이터리스트 상관없이 & evaluateStatus == I(현업담당자 평가 필요한 상태)
      // 수행도평가 진행 버튼 노출 조건 - 구매 담당자 (인프라)
      //  1) 수행도평가 진행여부 = "Y"
      //  2) 검수데이터리스트 상관없이 & evaluateStatus == P (구매담당자 평가 필요한 상태)

      //  수행도평가 진행 버튼 노출 조건 - 구매 담당자 (일반)
      //  1) 검수 데이터 리스트 없을 시 요청 버튼 숨김
      //  2) 검수 데이터 한건일때는 검수데이터와 상관이 없고 & evaluateStatus != "C" (평가완료 상태) 보이도록)
      //  3) 여러건일때는 검수 마지막 데이터의 직전 데이터 상태가 검수 완료된 상태 (구매팀 검수 (PURCHASE_INSPECT) 혹은 정산대기 (PURCHASE_CONFIRM))

      if (obj.contractSetting.evaluateYn == "Y" && obj.contractStatus == "C") {
        if (obj.category.indexOf("인프라") > -1) {
          // 인프라계약 -> 검수 담당자에게 이메일 알림. 검수담당자 평가 완료 후 구매담당자 확인시 BTN 변경
          if (obj.evaluateStatus == "I") this.pRequestEvaluate = true;
          if (obj.evaluateStatus == "P") this.pEvaluate = true;

          return;
        } else {
          // 일반계약
          // 검수 데이터 리스트 없을 시 요청 버튼 숨김
          if (
            !this.itemData.contractCalculateList ||
            this.itemData.contractCalculateList.length == 0
          )
            return;

          // 데이터 한건일때는 무조건 보이도록
          if (this.itemData.contractCalculateList.length == 1) {
            if (obj.evaluateStatus == "P") {
              this.pEvaluate = true;
            }
          }

          // 여러건일때는 직전 상태
          const num = this.itemData.contractCalculateList.length - 2;
          if (
            this.itemData.contractCalculateList[num] &&
            (this.itemData.contractCalculateList[num].stateCode == "PURCHASE_CONFIRM" ||
              this.itemData.contractCalculateList[num].stateCode == "PURCHASE_INSPECT") &&
            obj.evaluateStatus == "P"
          ) {
            this.pEvaluate = true;
          }
        }
      }
    },
    iEvaluateBtn(obj) {
      // 수행도평가 진행 버튼 노출 조건 - 검수 담당자 -
      // 1. 수행도평가 진행여부 = "Y" && 수행도평가 상태 완료 X && 계약이 완료된  상태
      // 2. 인프라 계약 : 검수데이터리스트(contractCalculateList)관계없이 진행버튼이 노출되어야한다.
      // 3. 일반 계약 : 1) 검수데이터리스트(contractCalculateList)가 존재하지않으면 return.
      //              2) 검수데이터리스트 마지막 데이터의 직전상태 : 검수완료. (구매팀 검수 (PURCHASE_INSPECT) 혹은 정산대기 (PURCHASE_CONFIRM))
      if (
        obj.contractSetting.evaluateYn == "Y" &&
        obj.evaluateStatus != "C" &&
        obj.contractStatus == "C"
      ) {
        if (obj.category.indexOf("인프라") > -1) {
          if (obj.evaluateStatus == "I") this.iEvaluate = true;

          return;
        }

        if (!this.itemData.contractCalculateList) return;

        if (this.itemData.contractCalculateList.length == 1) {
          if (obj.evaluateStatus == "I") {
            this.iEvaluate = true;
          }
        }

        const num = this.itemData.contractCalculateList.length - 2;

        if (
          this.itemData.contractCalculateList[num] &&
          (this.itemData.contractCalculateList[num].stateCode == "PURCHASE_CONFIRM" ||
            this.itemData.contractCalculateList[num].stateCode == "PURCHASE_INSPECT") &&
          obj.evaluateStatus == "I"
        ) {
          this.iEvaluate = true;
        }
      }
    },
    showBtns(obj) {
      if (obj.contractStatus == "A") obj.contractStatus = "I";
      if (obj.contractStatus == "K") obj.contractStatus = "S";

      /* TODO : 표준화 계약검토요청 버튼처리
      if (this.signUseYn == 'Y') {
        if (obj.contractStatus == 'E') this.requestReview = true; // requestReview : 검토 요청
        if (obj.contractStatus == 'I') this.reviewProcessiong = true; // reviewProcessiong : 서명 요청 (업체 검토 중)
        if (obj.contractStatus == 'O') this.requestSign = true; // requestSign : 서명 요청
        if (obj.contractStatus == 'S') this.afterRequestSign = true; // afterRequestSign: 계약 확정 취소

        if (this.normalYn == 'Y') {
          if (obj.contractStatus == 'C' || obj.contractStatus == 'O' || obj.contractStatus == 'S')
            this.showDownload = true; // 계약서 다운로드 - 상태 : 검토완료 - 파트너사 확인 - 전자서명 요청(O) & 계약체결시
        } else {
          if (obj.contractStatus == 'C') this.showDownload = true; // 별도템플릿 사용시 상태가 서명요청부터 있으니 최초의 서명요청 & 검토완료를 제외한 계약체결완료시에만 노출되도록.
        }

        if (obj.contractStatus == 'P' || obj.contractStatus == 'E') this.showModify = true; // 수정은 검토요청까지만 . E,I
      } else {
        // TODO : 추후 리팩토링 필요.
        if (obj.contractStatus == 'C' || obj.contractStatus == 'P') this.showModify = true; // 수정
      }
       */
      //TODO : 표준화 수정만 살려둠
      if (obj.contractStatus == "P" || obj.contractStatus == "E") this.showModify = true; // 수정은 검토요청까지만 . E,I // 수정

      if (obj.contractStatus == "P" || obj.contractStatus == "E") this.showDelete = true; // 삭제
      //if (obj.contractStatus == 'P' ) this.showDelete = true; // 삭제
    },
    onClickRequestEvaluate() {
      this.getRequestEvaluate();
    },
    async getRequestEvaluate() {
      const path = `${this.$apiPath.EVALUATE}/${this.itemData.contractId}`;

      const result = await ApiService.shared.getData(path);

      if (result.code !== "200") {
        this.alert(result.text);

        return;
      }

      this.alert("평가요청이 완료되었습니다.");
    },
    async getEvaluatePopupData() {
      const status = this.isInspect ? "I" : "P";
      const path = `${this.$apiPath.EVALUATE_CODE}/${this.itemId}/${status}`;

      const result = await ApiService.shared.getData(path);

      // text alter창으로 띄우기
      if (result.code != "200") {
        this.alert(result.text);
        return;
      }

      if (!result.data) return;

      this.isPopPerformanceEvaluate = true;

      // 구매담당자 조합
      result.data.purchaseManager = this.itemData.managerId + "(" + this.itemData.managerName + ")";
      this.evaluatePopupData = result.data;

      // let obj = {};
      // obj.companyName = result.data.companyName;
      // obj.description = result.data.description;
      // obj.inspectManager = result.data.inspectManager;

      // this.contractInfo = obj;

      // let setKeywordDataList = result.data.code;

      // this.keywordDataList = setKeywordDataList.filter(item => {
      //   if(item.name != '키워드추가'){
      //     return item;
      //   }
      // });
    },
    async onClickPreviewPop() {
      if (!this.previewId) {
        const url = `${this.$apiPath.CONTRACT}/template/preview`;
        const obj = this.itemData;
        if (obj.contractAddInfo)
          obj.contractAddInfo.finalPriceKr = getMoneyToKorean(obj.finalPrice);
        const result = await ApiService.shared.postData(url, obj);
        const { code, data, text } = result;

        if (code !== "200") {
          this.alert(text);
          return;
        }

        this.previewId = data.previewId;
      }
      //this.$windowOpen(`/templatePreview?previewId=${this.previewId}`);
      this.isTemplatePopup = true;
    },

    showPreviewPopup() {
      this.isTemplatePopup = true;
    },
    onClickClosePreviewPopup() {
      this.isTemplatePopup = false;
      //this.previewId = '';
    },
    onClickRequestReview() {
      const params = {
        text: "저장된 계약서를 검토요청 하시겠습니까? ",
        onClickY: () => this.requestReviewY(),
      };

      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, params);
    },
    onClickreviewProcessiong() {
      this.alert("검토 확인이 필요합니다.");

      return;
    },
    onClickConfirm() {
      const params = {
        text: "전자서명 요청을 하시겠습니까?",
        onClickY: this.contractInspect,
      };

      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, params);
      //this.contractInspect();
    },
    onClickOfflineConfirm() {
      const params = {
        text: "offline계약입니다.<br /> 계약을 진행하시겠습니까?<br /><strong style='color:red'>offline계약은 전자서명 요청없이 전자결재로 넘어갑니다.</strong>",
        onClickY: this.contractInspect,
      };

      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, params);
    },
    async contractInspect() {
      const obj = this.itemData;
      obj.contractStatus = "I";

      const result = await ApiService.shared.putData(
        `${this.$apiPath.CONTRACT}/${this.itemId}`,
        obj,
      );

      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }
      //pdf 생성 이슈로 인하여 강재로 만든다
      const path = `${this.$apiPath.CONTRACT}/pdf/${this.itemId}`;
      const pdfresult = await ApiService.shared.postData(path, obj);

      window.location.reload();
      //this.refreshPage(text);
    },
    refreshPage(text) {
      const contractId = this.contractId ? this.contractId : text;
      // 저장 성공시 해당 화면 refresh
      // const afterPath = this.contractId ? `${ this.$routerPath.CONTRACT_VIEW }/${ this.contractId }` : this.$routerPath.CONTRACT_LIST;
      const afterPath = `${this.$routerPath.CONTRACT_VIEW}/${contractId}`;
      this.$router.push({
        path: afterPath,
      });
    },
    onClickRequestSign() {
      // if( this.contractStatus == "I"){
      //   this.alert("검토 확인이 필요합니다.");

      //   return;
      // }

      const params = {
        text: "계약서를 서명요청 하시겠습니까? ",
        onClickY: () => this.requestSignY(),
      };

      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, params);
    },
    requestReviewY() {
      const path = `${this.$apiPath.CONTRACT}/send/${this.itemData.contractId}?isReview=Y`;

      let obj = Object.assign({}, this.itemData);

      if (!obj.contractAddInfo) {
        obj.contractAddInfo = this.makeContractAddInfo();
      }

      obj.contractStatus = "I";
      this.callRequestReview(obj, path);
    },
    async callRequestReview(obj, path) {
      var result = await ApiService.shared.postData(path, obj);

      if (result.code !== "200") {
        this.alert(result.text);
        return;
      }

      this.alert("검토 요청이 처리되었습니다.");

      this.itemId = this.$route.params.id;

      this.getData(this.itemId);

      this.requestReview = false;
      //this.requestSign   = true;
    },
    requestSignY() {
      const path = `${this.$apiPath.CONTRACT}/send/${this.itemData.contractId}?isReview=N`;

      let obj = Object.assign({}, this.itemData);

      if (!obj.contractAddInfo) {
        obj.contractAddInfo = this.makeContractAddInfo();
      }
      obj.contractStatus = "S";

      this.callRequestSign(obj, path);
    },
    async callRequestSign(obj, path) {
      var result = await ApiService.shared.postData(path, obj);

      if (result.code !== "200") {
        this.alert(result.text);
        return;
      }

      this.alert("서명 요청이 처리되었습니다.");

      this.requestReview = false;
      this.requestSign = false;
      this.afterRequestSign = true;

      this.itemId = this.$route.params.id;

      this.getData(this.itemId);
    },
    onClickCancelContract() {
      const params = {
        text: "진행중인 계약을 취소 하시겠습니까? ",
        onClickY: () => this.cancelContract(),
      };
      // 단, 파트너사 서명 완료시 계약 취소 불가. 파트너사 서명 이전 계약건만 취소 가능. * 파트너사 서명 완료시 계약완료 변경
      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, params);
    },
    cancelContract() {
      const path = `${this.$apiPath.CONTRACT}/cancel/${this.itemData.contractId}`;

      this.callCancelContract(path);
    },
    async callCancelContract(path) {
      var result = await ApiService.shared.deleteData(path);

      if (result.code !== "200") {
        this.alert(result.text);
        return;
      }

      this.alert("계약 확정 취소 처리되었습니다.");

      this.itemId = this.$route.params.id;

      this.getData(this.itemId);
    },
    onClickContractDownload() {
      if (
        this.itemData.resultId == "-" ||
        !this.itemData.contractId ||
        this.itemData.contractId == ""
      ) {
        this.alert("계약서를 다운받으실 수 없습니다.");

        return;
      }

      let path = "";
      // 이슈때문에 주석처리 되었던 부분 별도템플릿 원복 20.11.03
      if (this.itemData.contractStatus == "C" || this.itemData.contractSetting.normalYn == "N") {
        path = `${this.$apiPath.CONTRACT}/download/${this.itemData.contractId}?isReview=N`;
      } else {
        path = `${this.$apiPath.CONTRACT}/download/${this.itemData.contractId}?isReview=Y`;
      }
      // 별도 템플릿 이전의 분기처리
      // if(this.itemData.contractStatus == 'C') {
      //   path = `${ this.$apiPath.CONTRACT }/download/${ this.itemData.contractId }?isReview=N`;
      // } else {
      //    path = `${ this.$apiPath.CONTRACT }/download/${ this.itemData.contractId }?isReview=Y`;
      // }

      this.callDownload(path);
    },
    async callDownload(path) {
      var result = await ApiService.shared.getFile(path);

      //downloadFile( result , this.templateName );

      const { purchaseOrderFile, estimateFile, contractFile, attachFileList, description } =
        this.itemData;

      downloadZip(result, description);
      // if(purchaseOrderFile || estimateFile
      //         || contractFile  || attachFileList ) {
      //      downloadZip(result, description);
      //  } else {
      //      downloadPdf( result , description);
      //  }
    },
    makeContractAddInfo() {
      let obj = {
        contractId: "",
        finalPriceKr: "",
        monthPeriod: "",
        paymentDay: "",
        paymentDayKr: "",
        vat: "",
        warrantyPeriodKr: "",
      };

      return obj;
    },
    onClickRequestDownload() {
      const obj = this.itemData;
      if (obj.contractAddInfo) obj.contractAddInfo.finalPriceKr = getMoneyToKorean(obj.finalPrice);

      this.callDownloaRequestDocs(obj);
    },
    async callDownloaRequestDocs(obj) {
      const url = `${this.$apiPath.CONTRACT}/download/before`;

      const result = await ApiService.shared.postFile(url, obj);

      if (!result) {
        this.alert("다운로드를 실패했습니다.");
        return;
      }

      const { purchaseOrderFile, estimateFile, contractFile, attachFileList, description } =
        this.itemData;
      downloadPdf(result, description);
      //  if(purchaseOrderFile || estimateFile
      //           || contractFile  || attachFileList ) {
      //        downloadZip(result, description);
      //    } else {
      //        downloadPdf( result , description);
      //    }
    },

    async onClickContractPdfView() {
      if (this.attachFile?.electronicDocumentFile.length < 1) {
        this.pdfCheckError();
        return;
      }
      const { sysFilePath, orgFile, attachType } = this.attachFile.electronicDocumentFile[0];
      const arrayBuffer = await ApiService.shared.getFile(
        `${this.$apiPath.ATTACH_FILE}/${sysFilePath}`,
      );
      const objectUrl = ObjectURLPdf(arrayBuffer);
      this.pdfViewSrc = objectUrl;
    },
    pdfCheckLoaded() {
      this.$windowOpen(this.pdfViewSrc);
    },
    async pdfCheckError(error) {
      // const params = {
      //   text: `PDF 문서를 읽을수 없습니다.<br />PDF 문서를 다시 만드시겠습니까?`,
      //   onClickY: () => {
      //     this.pdfGenerator();
      //   },
      // };
      // this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, params);
      this.pdfGenerator();
    },
    async pdfGenerator() {
      const path = `${this.$apiPath.CONTRACT_PDF_GENERATOR}/${this.itemData.contractId}`;
      const result = await ApiService.shared.putData(path);
      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }
      window.location.reload();
    },

    onClickContractMiddleClose() {
      if (!this.changeReason) {
        this.alert("중도종결 사유를 입력해주세요");
        return;
      }
      const params = {
        text: `종도종결을 진행하시겠습니까?`,
        onClickY: () => {
          this.submitContractMiddleClose();
        },
      };
      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, params);
    },

    async submitContractMiddleClose() {
      const path = `${this.$apiPath.CONTRACT}/termination`;
      const obj = {
        contractId: `${this.itemId}`,
        changeReason: this.changeReason,
      };

      const result = await ApiService.shared.putData(path, obj);
      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }
      this.getData(this.itemId);
    },
    onClickChangeContract() {
      //변경계약
      const params = {
        text: `변경계약을 진행하시겠습니까?<br />변경계약은 바로 변경계약서를 작성 하셔야됩니다.`,
        onClickY: () => {
          this.$router.push({
            name: `${this.$routerPath.CONTRACT_RENEWAL}`,
            params: { contractId: `${this.itemId}`, type: "renewal" },
          });
        },
      };
      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, params);
    },
    onClickWithdraw() {
      const params = {
        text: "임시저장상태로 돌아갑니다.<br />계약서를 회수 하시겠습니까?",
        onClickY: () => {
          this.submitContractWithdraw();
        },
      };
      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, params);
    },
    async submitContractWithdraw() {
      const path = `${this.$apiPath.CONTRACT_WITHDRAWAL}/${this.itemId}`;
      const result = await ApiService.shared.putData(path);
      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }

      this.getData(this.itemId);
    },
    onClickPdfRegen() {
      const params = {
        text: "전자결재 문서를 재생성하시겠습니까?",
        onClickY: () => {
          this.contractPdfGenerator();
        },
      };
      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, params);
    },
    async contractPdfGenerator() {
      const path = `${this.$apiPath.CONTRACT_PDF_GENERATOR}/${this.itemId}`;
      const result = await ApiService.shared.putData(path);
      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }

      this.getData(this.itemId);
    },
  },
};
</script>
