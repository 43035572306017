<template>
  <Fold title="계약 조건">
    <TableView>
      <template v-slot:body>
        <tr>
          <th>계약 형태</th>
          <td class="td_text">
            {{ itemData.contractTypeName || "" }}
          </td>
          <th>계약서 자동연장</th>
          <td>{{ itemData.extendYn === true ? "Y" : "N" }}</td>
        </tr>
        <tr>
          <th>하도급 여부</th>
          <td>{{ itemData.subContractYn === true ? "Y" : "N" }}</td>
          <th>경쟁비딩 여부</th>
          <td>{{ itemData.bidYn === true ? "Y" : "N" }}</td>
        </tr>
        <tr>
          <th>정산 여부</th>
          <td>{{ itemData.calculateYn === true ? "Y" : "N" }}</td>
          <th>계약이행보증보험</th>
          <td>{{ itemData.insuranceYn === true ? "Y" : "N" }}</td>
        </tr>
        <tr>
          <th>비딩참여 업체</th>
          <td colspan="3" class="td_text">
            {{ itemData.bidCompany || "" }}
          </td>
        </tr>
      </template>
    </TableView>
  </Fold>
</template>

<script>
import Fold from "@/components/common/fold/Fold";
import TableView from "@/components/shared/tableView/TableView";

export default {
  name: "ContractCondition",
  components: {
    Fold,
    TableView,
  },
  props: {
    itemData: Object,
  },
};
</script>
