<template>
  <TableViewWithTitle title="협력사 담당자 정보">
    <template v-slot:body>
      <tr>
        <th>협력사 담당자</th>
        <td class="td_text" colspan="3">
          {{ contractPerson.name }}
        </td>
      </tr>
      <tr>
        <th>휴대전화</th>
        <td>{{ contractPerson.mobile }}</td>
        <th>이메일</th>
        <td>{{ contractPerson.email }}</td>
      </tr>
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";

export default {
  name: "SupplyPartnerManagerInfoInput",
  components: {
    TableViewWithTitle,
  },
  props: {
    contractPerson: Object,
  },
  computed: {},
};
</script>
