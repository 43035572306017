<template>
  <TableViewWithTitle title="담당자 정보">
    <template v-slot:header>
      <tr>
        <th colspan="2">구매 담당자</th>
        <th colspan="2">구매 요청자</th>
      </tr>
    </template>
    <template v-slot:body>
      <tr>
        <th>구매 담당자</th>
        <td>{{ `${managerData.managerName} (${managerData.managerId})` }}</td>
        <th>구매 요청자</th>
        <td>{{ `${computedBManagerData.name} (${computedBManagerData.loginId})` }}</td>
      </tr>
      <tr>
        <th>전화번호 (내선)</th>
        <td>{{ managerData.managerTel | nullToDash }}</td>
        <th>전화번호 (내선)</th>
        <td>{{ computedBManagerData.tel | nullToDash }}</td>
      </tr>
      <tr>
        <th>이메일</th>
        <td>{{ managerData.managerEmail | nullToDash }}</td>
        <th>이메일</th>
        <td>{{ computedBManagerData.email | nullToDash }}</td>
      </tr>
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";

export default {
  name: "ManagerInfo",
  components: {
    TableViewWithTitle,
  },
  props: {
    managerData: Object,
    contractManagerList: Array,
  },
  computed: {
    computedBManagerData() {
      const bManagerData = this.contractManagerList.find((item) => item.code === "B");
      return bManagerData;
    },
    computedIManagerData() {
      const iManagerData = this.contractManagerList.find((item) => item.code === "I");
      return iManagerData;
    },
  },
};
</script>
