<template>
  <div class="area_view">
    <Board
      :disableHover="true"
      :dataList="dataList"
      :isScroll="true"
      :scrollHeight="styleHeight"
      :showPagination="false"
    >
      <template v-slot:colgroup>
        <col style="width: 132px" />
        <!-- 일시 -->
        <col style="width: 104px" />
        <!-- 상태 -->
        <col style="width: 218px" />
        <!-- 의견 -->
        <col style="width: 120px" />
        <!-- 담당자 -->
        <col />
        <!-- 첨부파일 -->
      </template>
      <template v-slot:tr>
        <th style="width: 132px">일시</th>
        <th style="width: 104px">상태</th>
        <th style="width: 218px">의견</th>
        <th style="width: 120px">담당자</th>
        <th style="width: 190px">첨부파일</th>
      </template>
      <template v-slot:rows>
        <template v-for="(item, index) in dataList">
          <InspectConfirmPopupLine :key="index" :rowData="item" />
        </template>
      </template>
      <template v-slot:emptyList>
        <tr>
          <td colspan="6" class="td_empty">검색 결과가 없습니다</td>
        </tr>
      </template>
    </Board>
  </div>
</template>

<script>
import Board from "@/components/common/board/Board";
import InspectConfirmPopupLine from "./InspectConfirmPopupLine";

export default {
  name: "InspectHistory",
  components: {
    Board,
    InspectConfirmPopupLine,
  },
  props: {
    dataList: Array,
    isInspect: Boolean, // fasle 일때 가운데 테이블 노출
    cancelYn: Boolean, // false 일때 검수 증빙 문서 노출
  },
  computed: {
    styleHeight() {
      if (this.isInspect) {
        // 649
        if (this.cancelYn) {
          return "352px";
        } else {
          return "246px";
        }
      } else {
        // 673
        if (this.cancelYn) {
          return "205px";
        } else {
          return "99px";
        }
      }
    },
  },
};
</script>
