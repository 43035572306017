<template>
  <Fold title="기타 지불방법">
    <TableView>
      <template v-slot:body>
        <tr>
          <th>납품 기한</th>
          <td>{{ itemData.etcDeliveryDate | dateStringFormat }}</td>
          <th>계약금액</th>
          <td>
            <div class="group_form">
              <div class="txt_view">
                {{ itemData.etcContractPrice ? itemData.currencyCode : "" }}
              </div>
              <div class="txt_view_r">
                {{ itemData.etcContractPrice || "" }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <th>지급 비율</th>
          <td>{{ itemData.etcPaymentRate | percentValue }}</td>
          <th>지급 금액</th>
          <td>
            <div class="group_form">
              <div class="txt_view">
                {{ itemData.etcPayment ? itemData.currencyCode : "" }}
              </div>
              <div class="txt_view_r">
                {{ itemData.etcPayment || "" }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <th>하자보증 비율</th>
          <td colspan="3">
            {{ itemData.etcDefactRate | percentValue }}
          </td>
        </tr>
      </template>
    </TableView>
  </Fold>
</template>

<script>
import Fold from "@/components/common/fold/Fold";
import TableView from "@/components/shared/tableView/TableView";

export default {
  name: "PaymentMethod",
  components: {
    Fold,
    TableView,
  },
  props: {
    itemData: Object,
  },
};
</script>
