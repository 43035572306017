<template>
  <div class="area_view">
    <TableViewWithTitle title="납품 정보">
      <template v-slot:colgroup>
        <col style="width: 192px" />
        <col />
      </template>
      <template v-slot:body>
        <tr>
          <th>대금 지급 방법</th>
          <td>
            {{ itemData.payMethod }}
          </td>
        </tr>
        <tr>
          <th>대금 지급 조건</th>
          <td>
            {{ itemData.payCondition }}
          </td>
        </tr>
        <tr>
          <th>납품 장소</th>
          <td>
            {{ itemData.goodsPlace }}
          </td>
        </tr>
        <tr>
          <th>계약&#47;하자 보증금</th>
          <td>
            {{ itemData.deposit }}
          </td>
        </tr>
        <tr>
          <th>기타</th>
          <td>
            {{ itemData.otherTerms }}
            <!-- <Textarea
              :value.sync="itemData.otherTerms" /> -->
          </td>
        </tr>
      </template>
    </TableViewWithTitle>
  </div>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";

export default {
  name: "PaymentInfo",
  components: {
    TableViewWithTitle,
  },
  props: {
    itemData: Object,
  },
};
</script>
