<template>
  <TableViewWithTitle title="협력사 정보">
    <template v-slot:body>
      <tr>
        <th>업체명</th>
        <td class="td_text">
          <template v-if="contractPerson.userNum">
            <button
              class="link_subject"
              @click="$windowOpen(`${$routerPath.PARTNER_VIEW}/${contractPerson.userNum}`)"
            >
              {{ contractPerson.companyName }}
            </button>
          </template>
          <template v-else>
            {{ contractPerson.companyName }}
          </template>
        </td>
        <th>대표이사명</th>
        <td class="td_text">
          {{ contractPerson.ceo }}
        </td>
      </tr>
      <tr>
        <th>사업자번호</th>
        <td colspan="3">{{ contractPerson.corporateNum }}</td>
      </tr>
      <tr>
        <th>본사 주소</th>
        <td colspan="3" class="td_text">
          {{ contractPerson.zipcode }}<br />
          {{ `${contractPerson.address} `
          }}{{ `${contractPerson.address2 ? contractPerson.address2 : ""}` }}
        </td>
      </tr>
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";

export default {
  name: "SupplyPartnerInfo",
  components: {
    TableViewWithTitle,
  },
  props: {
    contractPerson: Object,
  },
  computed: {},
};
</script>
