<template>
  <Popup popupType="tableType" width="788px">
    <div class="section_comm">
      <div class="head_section">
        <h3 class="tit_section">검수 확인</h3>
        <p class="desc_tip">
          <span class="ico_purchase ico_exclam" />검수 확인을 위해 의견을 입력헤주시기 바랍니다.
        </p>
      </div>
      <div class="body_section">
        <div class="area_view">
          <TableViewWithTitle title="검수 요청 정보">
            <template v-slot:colgroup>
              <col style="width: 192px" />
              <col />
            </template>
            <template v-slot:body>
              <tr>
                <th>금액</th>
                <td />
              </tr>
              <tr>
                <th>납품일</th>
                <td />
              </tr>
              <tr>
                <th>첨부파일</th>
                <td />
              </tr>
              <tr>
                <th>비고</th>
                <td />
              </tr>
            </template>
          </TableViewWithTitle>
        </div>
        <div class="area_view">
          <TableView>
            <template v-slot:colgroup>
              <col style="width: 192px" />
              <col />
            </template>
            <template v-slot:body>
              <tr>
                <th>파트너사 검수 완료일</th>
                <td />
              </tr>
              <tr>
                <th>파트너사 검수 의견</th>
                <td />
              </tr>
            </template>
          </TableView>
        </div>
        <InspectContractInfo :inspectData="inspectData" />
        <InspectHistory :dataList="histories" :isInspect="isInspect" :cancelYn="cancelYn" />
      </div>
    </div>
    <div class="group_btn">
      <button type="button" class="btn_fourthly btn_medium" @click="$emit('onClickClose')">
        닫기
      </button>
      <button type="button" class="btn_secondary btn_medium">거부</button>
      <button type="button" class="btn_primary btn_medium">확인</button>
    </div>
  </Popup>
</template>

<script>
import Popup from "@/components/layout/popup/Popup";
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import TableView from "@/components/shared/tableView/TableView";

import InspectContractInfo from "./InspectContractInfo";
import InspectHistory from "./InspectHistory";

import LocalStorageManager from "@/LocalStorageManager";

export default {
  name: "PartnerInspectPopup",
  components: {
    Popup,
    TableViewWithTitle,
    TableView,
    InspectContractInfo,
    InspectHistory,
  },
  props: {
    inspectData: Object,
  },
  computed: {
    histories() {
      const { contractInspectHistoryList } = this.inspectData;

      return contractInspectHistoryList || [];
    },
    isInspect() {
      return LocalStorageManager.shared.getIsInspect();
    },
    cancelYn() {
      const { cancelYn } = this.inspectData;

      return cancelYn || false;
    },
  },
};
</script>
