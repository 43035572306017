<template>
  <TableViewWithTitle title="계약 설정">
    <template v-slot:body>
      <tr>
        <th>구매요청서 연결</th>
        <td colspan="3">
          <ConnectViewOnlyOne
            :dataList="purchaseWfContract"
            @onClickConnect="(item) => $emit('onClickConnectPurchaseWfPost', item)"
          />
        </td>
      </tr>

      <tr v-if="estimateContract">
        <th>견적서 연결</th>
        <td colspan="3">
          <ConnectViewOnlyOne
            :dataList="estimateContract"
            @onClickConnect="(item) => $emit('onClickPopEstimatePrint', item)"
          />
        </td>
      </tr>
      <tr>
        <th>프로젝트코드</th>
        <td>{{ itemData.projectCode }}</td>
        <th>프로젝트명</th>
        <td v-html="itemData.projectName"></td>
      </tr>
      <tr>
        <th>계약명</th>
        <td colspan="3" v-html="itemData.description || ''"></td>
      </tr>
      <tr>
        <th>계약서 템플릿</th>
        <td>{{ templateName }}</td>
        <th>계약기간</th>
        <td>
          {{ `${itemData.contractStartDate}` | dateStringFormat }} ~
          {{ `${itemData.contractEndDate}` | dateStringFormat }}
        </td>
      </tr>
      <tr>
        <th>계약 체결일</th>
        <td>{{ contractAddInfo.signDate | dateStringFormat }}</td>
        <th>납품기한</th>
        <td>
          <template v-if="orderPayment">
            {{ orderPayment.etcDeliveryDate | dateStringFormat | nullToDash }}
          </template>
        </td>
      </tr>
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import ConnectViewOnlyOne from "@/components/common/connect/ConnectViewOnlyOne";

import { getDateString } from "@/utils/dateUtils";
import { getCategoryDepthText, getContractDept } from "@/utils/spUtils";
import { addCommaForMoney } from "@/utils/stringUtils";

export default {
  name: "ContractSetting",
  components: {
    TableViewWithTitle,
    ConnectViewOnlyOne,
  },
  props: {
    itemData: Object,
    signUseYn: String,
    performanceUseYn: String,
    templateName: String,
    normalYn: String,
    purchaseWfContract: Object,
    estimateContract: Object,
    contractPayment: Object,
    orderPayment: Object,
  },
  data() {
    return {
      dataorderPayment: this.orderPayment,
    };
  },
  computed: {
    isTemplateName() {
      if (this.templateName == "") return "선택된 템플릿명이 노출됩니다.";
      return this.templateName;
    },
    contractAddInfo() {
      if (this.itemData.contractAddInfo) {
        return this.itemData.contractAddInfo;
      }
      return "";
    },
  },
};
</script>
