<template>
  <TableViewWithTitle title="계약 정보">
    <template v-if="!isInspect" v-slot:body>
      <tr>
        <th>계약 상태</th>
        <td>{{ contractStatusName }}</td>
        <th>계약 구분</th>
        <td>{{ itemData.contractDivName || "" }}</td>
      </tr>
      <tr>
        <th>계약 기간</th>
        <td>{{ dateRange }}</td>
        <th>계약 ID</th>
        <td>{{ itemData.contractCid || "" }}</td>
      </tr>
      <tr>
        <th>계약 체결일</th>
        <td>{{ contractAddInfo.signDate | dateStringFormat }}</td>
        <th>계약명</th>
        <td v-html="itemData.description || ''" />
      </tr>
      <tr>
        <th>구매 담당자</th>
        <td colspan="3">
          {{ itemData.managerId | idWithName(itemData.managerName) }}
        </td>
      </tr>
      <tr>
        <th>계약 요청자</th>
        <td>{{ bManagerName }}</td>
        <th>요청부서</th>
        <td class="td_text">
          {{ contractDept || "" }}
        </td>
      </tr>
      <tr>
        <th>입찰 공고번호</th>
        <td>
          <span v-if="isInspect">{{ itemData.postCid || "" }}</span>
          <router-link
            v-else
            class="link_subject"
            target="_blank"
            rel="opener"
            :to="`${$routerPath.ANNOUNCE_VIEW}/${itemData.postCid}`"
          >
            {{ itemData.postCid || "" }}
          </router-link>
        </td>
        <th>발주 번호</th>
        <td>
          <span v-if="isInspect">{{ itemData.postCid || "" }}</span>
          <router-link
            v-else
            class="link_subject"
            :to="`${$routerPath.ORDER_VIEW}/${itemData.orderCid}`"
          >
            {{ itemData.orderCid || "" }}
          </router-link>
        </td>
      </tr>

      <tr>
        <th>절감 정보</th>
        <td colspan="3">
          <div class="group_form">
            <span class="txt_view_l">{{
              itemData.reduceStandardName || itemData.reduceStandard
            }}</span>
            <span class="txt_view_l">{{ firstPrice }}</span>
            <span class="bar_view" />
            <strong class="w100 txt_view_c">절감액 (절감율%)</strong>
            <span class="txt_view_r">{{ itemData.cutOffPrice || "0 (0.0%)" }}</span>
          </div>
        </td>
      </tr>
      <tr>
        <th>최종 계약 금액</th>
        <td colspan="3">
          <div class="group_form">
            <span class="txt_view_l">{{ itemData.currencyCode || "" }}</span>
            <span class="txt_view_l">{{ finalPrice }}</span>
            <span v-if="isIncludeVat" class="txt_view_r tc_red">* 부가세(VAT) 포함</span>
            <span v-if="isNotIncludeVat" class="txt_view_r tc_red">* 부가세(VAT) 별도</span>
          </div>
        </td>
      </tr>
      <tr>
        <th>원천세</th>
        <td>{{ contractAddInfo.withholdingTax || "" }}</td>
        <th>대금지급기한</th>
        <td>
          {{ contractAddInfo.paymentDay }}
          일
        </td>
      </tr>
      <tr>
        <th>납품기한</th>
        <td>{{ contractPayment.etcDeliveryDate | dateStringFormat }}</td>
        <th>무상 하자보증기간</th>
        <td colspan="3">
          {{ contractAddInfo.warrantyPeriod | monthValue }}
        </td>
      </tr>

      <tr>
        <th>비고</th>
        <td colspan="3" class="h96 td_text" v-html="itemData.negoMemo || ''">
          비고 내용이 보여집니다.
        </td>
      </tr>
    </template>
    <template v-else v-slot:body>
      <tr>
        <th>계약명</th>
        <td class="td_text" v-html="itemData.description || ''" />
        <th>계약 기간</th>
        <td>{{ dateRange }}</td>
      </tr>
      <tr>
        <th>최종 계약 금액</th>
        <td colspan="3">
          <div class="group_form">
            <span class="txt_view_l">{{ itemData.currencyCode || "" }}</span>
            <span class="txt_view_l">{{ finalPrice }}</span>
            <span v-if="isIncludeVat" class="txt_view_r tc_red">* 부가세(VAT) 포함</span>
            <span v-if="isNotIncludeVat" class="txt_view_r tc_red">* 부가세(VAT) 별도</span>
          </div>
        </td>
      </tr>
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";

import { getDateString } from "@/utils/dateUtils";
import { getCategoryDepthText, getContractDept } from "@/utils/spUtils";
import { addCommaForMoney } from "@/utils/stringUtils";

import LocalStorageManager from "@/LocalStorageManager";

export default {
  name: "ContractInfo",
  components: {
    TableViewWithTitle,
  },
  props: {
    itemData: Object,
    contractPayment: Object,
    isInspect: Boolean,
  },
  computed: {
    companyNameAndRequester() {
      const { companyCodeName, commonReqName } = this.itemData;

      if (!companyCodeName && !commonReqName) return "";

      if (!commonReqName) return companyCodeName;

      return `${companyCodeName} (${commonReqName})`;
    },
    contractDept() {
      const { contractDept } = this.itemData;
      if (!contractDept) return "";

      return getContractDept(contractDept);
    },
    dateRange() {
      const { contractStartDate, contractEndDate } = this.itemData;
      if (!contractStartDate || !contractEndDate) return "";

      return `${getDateString(contractStartDate)} ~ ${getDateString(contractEndDate)}`;
    },
    bManagerName() {
      const { contractManagerList } = this.itemData;
      if (!contractManagerList) return "";

      const bManager = contractManagerList.find((item) => item.code === "B");

      if (!bManager) return "";

      return bManager.loginId;
    },
    // 물품,하드웨어,서버 -> 물품>하드웨어>서버
    category() {
      const { category } = this.itemData;
      if (!category) return "";

      return getCategoryDepthText(category);
    },
    firstPrice() {
      const { firstPrice } = this.itemData;
      if (!firstPrice) return "";

      return addCommaForMoney(firstPrice);
    },
    finalPrice() {
      const { finalPrice } = this.itemData;
      if (!finalPrice) return "";

      return addCommaForMoney(finalPrice);
    },

    contractAddInfo() {
      if (this.itemData.contractAddInfo) {
        return this.itemData.contractAddInfo;
      }
      return "";
    },
    contractStatusName() {
      if (this.itemData.contractStatusName) {
        if (this.itemData.contractStatus == "O") return "계약 검토완료";
        return this.itemData.contractStatusName;
      }
      return "";
    },
    isIncludeVat() {
      if (this.itemData.contractAddInfo && this.itemData.contractAddInfo.vat == "포함") {
        return true;
      }
      return false;
    },
    isNotIncludeVat() {
      if (
        this.itemData.contractAddInfo &&
        (this.itemData.contractAddInfo.vat == "별도" || this.itemData.contractAddInfo.vat == "")
      ) {
        return true;
      }
      return false;
    },
    tenderYnName() {
      if (this.itemData.requestContractList && this.itemData.requestContractList.length > 0) {
        return this.itemData.requestContractList[0].tenderYnName;
      }
      return "";
    },
    contractYn() {
      if (this.itemData.requestContractList && this.itemData.requestContractList.length > 0) {
        return this.itemData.requestContractList[0].contractYn ? "^_^" : "-_-";
      }
      return "";
    },
  },
};
</script>
<style scoped>
.group_form .bar_view {
  margin-top: 0;
  margin-bottom: 0;
}
.group_form .txt_view_l,
.group_form .txt_view_c,
.group_form .txt_view_r {
  line-height: 1.5;
}
</style>
