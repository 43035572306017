import { getDateString } from "@/utils/dateUtils";
import { addCommaForMoney } from "@/utils/stringUtils";

const InspectContractInfoMixin = {
  props: {
    inspectData: Object,
  },
  computed: {
    dateRange() {
      const { contractStartDate, contractEndDate } = this.inspectData;
      if (!contractStartDate || !contractEndDate) return "";

      return `${getDateString(contractStartDate)} ~ ${getDateString(contractEndDate)}`;
    },
    companyName() {
      const { companyName } = this.inspectData;

      return companyName || "";
    },
    finalPrice() {
      const { finalPrice } = this.inspectData;
      if (!finalPrice) return "";

      return addCommaForMoney(finalPrice);
    },
    // unitPrice() {
    //   const { unitPrice } = this.inspectData;
    //   if( !unitPrice ) return '';

    //   return addCommaForMoney( unitPrice );
    // },
    inspectPrice() {
      const { inspectPrice } = this.inspectData;
      if (!inspectPrice) return "";

      return addCommaForMoney(inspectPrice);
    },
  },
};
export default InspectContractInfoMixin;
