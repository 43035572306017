<template>
  <div class="area_view">
    <TableHead title="검수 정보" />
    <Board :disableHover="true" :dataList="dataList" :showPagination="false">
      <template v-slot:colgroup>
        <col style="width: 54px" />
        <!-- NO. -->
        <col style="width: 144px" />
        <!-- 정산ID -->
        <col style="width: 172px" />
        <!-- 검수 예정일 -->
        <col style="width: 215px" />
        <!-- 금액 -->
        <!-- <col style="width:136px;"> 구매요청번호 -->
        <!-- <col style="width:136px;"> 프로젝트 코드 -->
        <!-- <col style="width:117px;"> 파트너사 검수 필요 -->
        <!-- <col style="width:117px;"> 파트너사 검수 상태 -->
        <!-- <col style="width:118px;"> 파트너사 검수 확인 -->
        <col v-if="!isInspect" style="width: 172px" />
        <!-- 실비정산여부 -->
        <col style="width: 172px" />
        <!-- 내부 검수 필요 -->
        <col style="width: 172px" />
        <!-- 내부 검수 상태 -->
        <col style="width: 139px" />
        <!-- 내부 검수 확인 -->
      </template>
      <template v-slot:tr>
        <th>No.</th>
        <th>정산ID</th>
        <th>검수 예정일</th>
        <th>금액</th>
        <!-- <th>구매요청번호</th>
        <th>프로젝트 코드</th>
        <th>파트너사 검수 필요</th>
        <th>파트너사 검수 상태</th>
        <th>파트너사 검수 확인</th> -->
        <th v-if="!isInspect">실비정산여부</th>
        <th>내부 검수 필요</th>
        <th>내부 검수 상태</th>
        <th>내부 검수 확인</th>
      </template>
      <template v-slot:rows>
        <template v-for="(item, index) in dataList">
          <tr :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ item.calculateNum || "" }}</td>
            <td>{{ getCalculateDate(item) }}</td>
            <td>{{ getCalculatePrice(item) }}</td>
            <!-- <td>{{ item.draftCid || '' }}</td>
            <td></td>
            <td>{{ item.partnerInspectYn === true ? 'Y' : 'N' }}</td>
            <td>{{ item.inspectStatusName || '검수대기' }}</td>
            <td>
              <button type="button"  class='btn_fourthly btn_small' :disabled='isDisabledBtnPartnerInspect(item.partnerInspectYn, item.inspectReqStatus)'
              @click="onClickPartnerInspect( item )">{{ btnNamePartnerInspect(item.partnerInspectYn, item.inspectReqStatus) }}</button></td> -->
            <td v-if="!isInspect">
              {{ item.actualExpenseYn === true ? "Y" : "N" }}
            </td>
            <td>{{ item.inspectYn === true ? "Y" : "N" }}</td>
            <td>{{ item.statusCodeName || "" }}</td>
            <td>
              <button
                type="button"
                class="btn_fourthly btn_small"
                :disabled="isDisabled"
                @click="onClickInspectBtn(item)"
              >
                검수
              </button>
              <!-- 2차에 적용예정 -->
              <!-- :disabled='isDisabledBtnInspect(item.inspectYn, item.statusCodeName)'

               {{ btnNameInspect(item.inspectYn, item.statusCodeName) }} -->
            </td>
            <!--<td><button type="button" class="btn_fourthly btn_small"
              @click="onClickInspectBtn( item )">검수</button>
            </td>-->
            <!-- 무조건 검수 버튼 enabled -->
            <!-- :disabled="!item.inspectActivate" -->
          </tr>
        </template>
      </template>
      <template v-slot:emptyList>
        <tr>
          <td :colspan="isInspect ? 7 : 8" class="td_empty">정산 정보가 없습니다.</td>
        </tr>
      </template>
    </Board>
    <div class="tbl_foot">
      <div class="area_total">
        <dl>
          <dt>총금액</dt>
          <dd>
            {{ calculateAmount }}<span class="util_total">{{ itemData.currencyCode || "" }}</span>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import TableHead from "@/components/shared/TableHead";
import Board from "@/components/common/board/Board";

import { getMoneyToNumber, addCommaForMoney } from "@/utils/stringUtils";
import { getDateString } from "@/utils/dateUtils";

export default {
  name: "SettleInfo",
  components: {
    TableHead,
    Board,
  },
  props: {
    itemData: Object,
    isInspect: Boolean,
  },
  computed: {
    dataList() {
      const { contractCalculateList } = this.itemData;
      return contractCalculateList || [];
    },
    calculateAmount() {
      const result = this.getCalculateAmount();

      return addCommaForMoney(result);
    },
    isDisabled() {
      // 계약 체결 완료
      if (this.itemData.contractStatus == "C") return false;
      return true;
    },
  },
  methods: {
    getCalculateAmount() {
      const result = this.dataList.reduce((accumulator, currentValue) => {
        const calculatePrice = getMoneyToNumber(currentValue.calculatePrice);

        return accumulator + calculatePrice;
      }, 0);

      return result;
    },
    getCalculateDate(item) {
      const { calculateDate } = item;
      if (!calculateDate) return "";

      return getDateString(calculateDate);
    },
    getCalculatePrice(item) {
      const { calculatePrice } = item;
      if (!calculatePrice) return "";

      return addCommaForMoney(calculatePrice);
    },

    // 'W' 검수대기 | 'R' 검수거부 | 'Q' 검수요청 | 'C' 검수확인
    // 'INITIAL' 납품대기 | 'REQUESTER_INSPECT' 현업검수 | 'REQUESTER_REJECT' 현업검수반려 | 'PURCHASE_INSPECT' 구매팀검수 | 'PURCHASE_CONFIRM' 정산대기
    btnNamePartnerInspect(partnerInspectYn, inspectReqStatus) {
      if (inspectReqStatus == "Q" && this.isInspect) {
        // 검수요청상태이면서 검수관리자일경우
        return "검수";
      } else {
        return "보기";
      }
    },
    btnNameInspect(inspectYn, statusCodeName) {
      if (
        statusCodeName == "검수대기(현업)" ||
        (!this.isInspect && statusCodeName == "현업검수반려") ||
        (!this.isInspect && statusCodeName == "구매팀검수")
      ) {
        // 현업검수 || 관리자이면서 현업검수반려 || 관리자이면서 구매팀검수
        return "검수";
      } else {
        return "보기";
      }
    },
    isDisabledBtnPartnerInspect(partnerInspectYn, inspectReqStatus) {
      if (!partnerInspectYn) {
        // 파트너사 검수 필요가 없을경우
        return true;
      } else if (inspectReqStatus == "W" && !this.isInspect) {
        // 검수요청상태이면서 관리자일경우
        return true;
      } else {
        return false;
      }
      // return false;
    },
    isDisabledBtnInspect(inspectYn, statusCodeName) {
      if (!inspectYn) {
        // 내부 검수 필요가 없을경우

        return true;
      } else if (statusCodeName == "납품대기") {
        // 납품대기일 경우

        return true;
      } else {
        return false;
      }
    },
    onClickPartnerInspect(item) {
      const { calculateNum, contractId, stateCode } = item;
      if (!calculateNum || !contractId) return;

      this.$emit("onClickPartnerInspect", calculateNum, contractId, stateCode);
    },
    onClickInspectBtn(item) {
      const { calculateNum, contractId, stateCode } = item;
      if (!calculateNum || !contractId) return;

      this.$emit("onClickInspectBtn", calculateNum, contractId, stateCode);
    },
  },
};
</script>
